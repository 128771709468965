import React, { useEffect, useState } from "react";
import AdminSidebar from "../../components/Admin/AdminSidebar";
import AdminHeader from "../../components/Admin/AdminHeader";
import { Outlet } from "react-router-dom";
import "./AdminLayout.scss";
const AdminLayout = () => {
  return (
    <>
      <div>
        <div className='d-flex'>
          <div className=''>
            <AdminSidebar />
          </div>
          <div className=''>
            <div>
              <AdminHeader />
            </div>
            <div className='outlet_wrapper'>
              <Outlet />
            </div>
          </div>
        </div>
        
      </div>
    </>
  );
};

export default AdminLayout;
