import { Button, Form, Input, Checkbox, message } from "antd";
import React, { useContext, useEffect, useState } from "react";
import "./Login.scss";
import CustomInput from "../../../components/Admin/CustomInput";
import CustomPassword from "../../../components/Admin/CustomPassword";
import CustomButton from "../../../components/Admin/CustomButton";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "../../../utils/AxiosInstance";
import Cookies from "js-cookie";
import { AdminContext } from "../../../components/Context/admin.context";
import { LOGIN_API } from "../../../Api/Api";
import { ADMIN_HOME_ROUTE, FORGOT_PASSWORD_ROUTE } from "../../../Routes/Route";
const Login = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(AdminContext);
  const [loginForm] = Form.useForm();
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState();
  const gotoForgotPassword = () => {
    navigate(FORGOT_PASSWORD_ROUTE);
  };
  const gotoAdminHome = () => {
    console.log("clicked");
    navigate("/admin");
  };
  const handleLogin = async () => {
    console.log("inside handle login");
    console.log("password=", password);
    try {
      const response = await AxiosInstance.post(LOGIN_API, {
        email: userName,
        password: password,
      });

      if (response.status == 200) {
        message.success("login success");
        localStorage.setItem("name", response.data.name);
        Cookies.set("sessionId", response.data.role_id);
        Cookies.set("sessionName", response.data.name);
        Cookies.set("sessionEmail", response.data.email);
        navigate(ADMIN_HOME_ROUTE);
      } else {
        message.error("login error in else");
      }
    } catch (error) {
      console.log(error);
      message.error("login error");
    }
  };

  const isLoggedIn = () => {
    let authenticated = isAuthenticated();
    if (authenticated) {
      navigate(ADMIN_HOME_ROUTE);
    }
  };
  useEffect(() => {
    isLoggedIn();
  }, []);

  return (
    <>
      <div className='login-page'>
        <div className='login-container '>
          <div className='container-fluid pt-5'>
            <div className='row'>
              <div className='col-md-5 '>
                <div className='ps-5 pt-5 mt-3'>
                  <div className='d-flex justify-content-center'>
                    <div className='circle-login'></div>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <div className='login_heading mt-2'>Login To app</div>
                  </div>

                  <div className='mt-5'>
                    <Form onFinish={handleLogin} form={loginForm}>
                      <Form.Item name='userName'>
                        <CustomInput
                          placeholder='User Name'
                          onChange={(e) => {
                            setUserName(e.target.value);
                          }}
                        />
                      </Form.Item>
                      <Form.Item name='password'>
                        <CustomPassword
                          placeholder='Password'
                          onChange={(e) => {
                            console.log("password", e.target);
                            setPassword(e.target.value);
                          }}
                        />
                      </Form.Item>
                      <div className='mt-5'>
                        <CustomButton
                          buttonText='Login'
                          type='save'
                          htmlType='submit'
                        />
                      </div>
                    </Form>
                  </div>
                  <div className='mt-3'>
                    <div className='d-flex justify-content-between'>
                      <div className='d-flex'>
                        <div className='links'>Remember Me</div>
                        <div className='ms-2'>
                          <Checkbox />
                        </div>
                      </div>
                      <div
                        className='forgot-password-link links '
                        onClick={gotoForgotPassword}>
                        Forgot Password
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-md-7 '>
                <div className=''>
                  <div className='d-flex justify-content-center'>
                    <img src='/images/LoginImage.png' />
                  </div>
                  <div className='d-flex justify-content-center mt-2'>
                    <div className='login-heading-two'>Friendship App</div>
                  </div>
                  <div
                    style={{ textAlign: "center", width: "610px" }}
                    className='mt-2 ps-4 login-text-two'>
                    Lorem Ipsum has been the industry's standard dummy text ever
                    since the 1500s, when an unknown printer took a galley of
                    type and scrambled it to make a type specimen book. It has
                    survived not only five centuries,
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
