import React, { useEffect, useState } from 'react';
import { Button, Input, Typography, Radio, Row, Col, message } from 'antd';
import { BoldOutlined, ItalicOutlined, UnderlineOutlined } from '@ant-design/icons';
import JoditEditor from 'jodit-react';
import './TermsAndCondition.scss'
import CustomButton from '../../../components/Admin/CustomButton';
import { useNavigate } from 'react-router-dom';
import AxiosInstance from '../../../utils/AxiosInstance';
import { CREATE_TERMS_AND_CONDITION_API, GET_TERMS_AND_CONDITION_API } from '../../../Api/Api';



const copyStringToClipboard = function (str) {
	var el = document.createElement('textarea');
	el.value = str;
	el.setAttribute('readonly', '');
	el.style = { position: 'absolute', left: '-9999px' };
	document.body.appendChild(el);
	el.select();
	document.execCommand('copy');
	document.body.removeChild(el);
};



const buttons = [
  'paragraph',
  'font',
	'bold',
  'italic',
  'underline',
  'strikethrough',
	'align',
  'ol',
  'ul',
  'outdent',
	'indent',
	
  'fontsize',
  'link',
	'image',
	'video',
	'table',
	
	
];

const editorConfig = {
	readonly: false,
	toolbar: true,
	spellcheck: true,
	language: 'en',
	toolbarButtonSize: 'medium',
	toolbarAdaptive: false,
	showCharsCounter: false,
	showWordsCounter: false,
	showXPathInStatusbar: false,
	askBeforePasteHTML: false,
	askBeforePasteFromWord: false,
	//defaultActionOnPaste: "insert_clear_html",
	buttons: buttons,
	uploader: {
		insertImageAsBase64URI: true
	},
	width: '100%',
	height: '400px',
  
};


const TermsAndConditions = () => {
  const [data, setData] = useState('');
  const navigate = useNavigate()

  const handleCancel = ()=>{
    setData('')
  }

  const getData = ()=>{
    AxiosInstance.get(GET_TERMS_AND_CONDITION_API).then((res)=>{
      if(res.status === 200){

        if(res?.data){
          setData(res?.data?.terms_and_conditions)
        }
      }else{
        throw new Error('Something went wrong')
      }
    }).catch((err)=>{
      message.error('Something went wrong')
    })
  }

  useEffect(()=>{
    getData()
  },[])

  const handleConfirm = ()=>{
    if(data){
      AxiosInstance.post(CREATE_TERMS_AND_CONDITION_API,{
        termsAndConditions:data
      }).then((res)=>{
        if(res.status === 200){
          message.success('Terms and conditions added successfully').then(()=>{
            navigate('/admin/settings')
          })
         
        }else{
          message.error('Something unexpected happend')
        }
      })
    
    
    }else{
      message.error('Please add the Terms and conditions')
    }
  }
  return (
    <div className='pt-4 ps-5 pe-5'>
      <div className='heading'style={{font:'lato',fontSize:'35px',fontWeight:'500',lineHeight:'23px'}}>Terms & Conditions</div>
      <div className='mt-4'>
        <JoditEditor
        value={data}
				config={editorConfig}
				onChange={(value) => setData(value)}/>
      </div>
      <div className='mt-4'>
            <div className="d-flex justify-content-center ">
              <div className='me-4'>
                <CustomButton buttonText="save" type="save" onClick={handleConfirm} height="49px" width="179px" htmlType="submit"/>
              </div>
              <div>
                <CustomButton buttonText="cancel"  type="cancel" onClick={handleCancel} height="49px" width="179px"/>
              </div>
            </div>
          </div>
    </div>
  );
};

export default TermsAndConditions;
