import axios from "axios";

const AxiosInstance=axios.create({
    withCredentials: true,
    baseURL:process.env.REACT_APP_BASE_URL,
    // headers:{
    //     "Content-type": "application/json",
    // }
});

AxiosInstance.interceptors.request.use(
    function (config) {
     // config.withCredentials = true;
        return config;
      },
      function (error) {
        return Promise.reject(error);
      }
);

AxiosInstance.interceptors.response.use(
  function(config) {
    console.log("Axios Config : ", config)
    return config;
  }
)



export default AxiosInstance;