import React from 'react'
import './CustomDatePicker.scss'
import { DatePicker } from 'antd'
const CustomDatePicker = ({onChange,disabledDate}) => {
  return (
    <>
 
    <div className='date-wrapper'>
                    <DatePicker 
                    disabledDate={disabledDate}
                    placeholder=""
                    onChange={onChange}/>
    </div>
 
    </>
  )
}

export default CustomDatePicker