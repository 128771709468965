import { Input, Form, DatePicker, Button, Select, message } from 'antd'
import React, { useEffect, useState } from 'react'
import './EditNewPlan.scss'
import CustomButton from '../../../components/Admin/CustomButton'
import CustomAddButton from '../../../components/Admin/CustomAddButton'
import { BsPlus } from 'react-icons/bs'
import CustomModal from '../../../components/Admin/CustomModal'
import CustomInput from '../../../components/Admin/CustomInput'
import AxiosInstance from '../../../utils/AxiosInstance'
import { CREATE_SUBSCRIPTION_MANAGMENT_API, CREATE_SUBSCRIPTION_PLAN_API, EDIT_SUBSCRIPTION_MANAGMENT_API, LIST_SUBSCRIPTION_API } from '../../../Api/Api'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment/moment'
const EditNewPlan = () => {
    const [showAdFeatureModal, setShowAdFeatureModal] = useState(false);
    const {id} = useParams()
    const [data, setData] = useState({
        id:'',
        plan_name: '',
        plan_base_rate : '',
        plan_date: '',
        plan_duration: '',
        planFeatureIntitialMessageCount: '',
        planFeatureSuperLikeCount: '',
        planFeatureChatCount: '',
        planFeatureOptionToViewProfile: '',
        planFeatureFriendGiftCount: '',
        planFeatureAllowedNumberOfConnect: '',
        planFeatureDayPlanning: ''
    });
    const [editForm] = Form.useForm()
    const navigate = useNavigate()

    const validatePlanDuration = (_, value) => {
        if (value && value.length > 0) {
          if (value[0] === "0") {
            return Promise.reject(new Error("Plan duration cannot start with zero"));
          }
        }
        return Promise.resolve();
      };
      

    const getData = ()=>{
        let body = parseInt(id)
        AxiosInstance.post(LIST_SUBSCRIPTION_API,{id:parseInt(id)}).then((res)=>{
            if(res.status === 200){
                const date = moment(res?.data?.plan_date)
                setData(res?.data)
                editForm.setFieldsValue({
                    planName: res?.data?.plan_name,
                    planBaseRate: res?.data?.plan_base_rate,
                    planDate: date,
                    planDuration: res?.data?.plan_duration,
                    planFeatureIntitialMessageCount: res?.data?.planFeatureIntitialMessageCount,
                    planFeatureSuperLikeCount: res?.data?.planFeatureSuperLikeCount,
                    planFeatureChatCount: res?.data?.planFeatureChatCount,
                    planFeatureOptionToViewProfile: res?.data?.planFeatureOptionToViewProfile,
                    planFeatureFriendGiftCount: res?.data?.planFeatureFriendGiftCount,
                    planFeatureAllowedNumberOfConnect: res?.data?.planFeatureAllowedNumberOfConnect,
                    planFeatureDayPlanning: res?.data?.planFeatureDayPlanning
                })

            }else{
                throw new Error('Something went wronh')
            }
        }).catch((err)=>{
           message.error(err)
        })
    }

    useEffect(()=>{
            getData()
    },[])

    const editPlan = async()=>{
        try{   
            const response = await AxiosInstance.post(EDIT_SUBSCRIPTION_MANAGMENT_API,{
                id:data?.id,
                planName: data?.plan_name,
                planBaseRate: parseInt(data?.plan_base_rate),
                planDate: data?.plan_date, 
                planDuration: parseInt(data.plan_duration),
                // planFeatureIntitialMessageCount: parseInt(data.planFeatureIntitialMessageCount),
                // planFeatureSuperLikeCount: parseInt(data.planFeatureSuperLikeCount),
                // planFeatureChatCount: parseInt(data.planFeatureChatCount),
                // planFeatureOptionToViewProfile: parseInt(data.planFeatureOptionToViewProfile),
                // planFeatureFriendGiftCount: parseInt(data.planFeatureFriendGiftCount),
                // planFeatureAllowedNumberOfConnect:parseInt(data.planFeatureAllowedNumberOfConnect),
                // planFeatureDayPlanning: parseInt(data.planFeatureDayPlanning)
            })
            if(response.status===200){
                message.success("Plan updated successfully");
                navigate("/admin/subscription_management")
              }

        }catch(err){
            message.error(err.response.data.message)
        }
    }


    const handleCancle = ()=>{
       navigate("/admin/subscription_management")

    }
    return (
        <>
            <div className='pt-4 ps-5 pe-5'>
                <div>
                    <div className='create-newplan-heading'>Edit Plan</div>
                </div>
                <div className='mt-4'>
                    <div>
                        <Form
                        form={editForm}
                        onFinish={editPlan}
                        >
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <div className='form-label-newplan'>Plan Name</div>
                                        <div className='mt-1'>
                                            <Form.Item name='planName' rules={[{required:true, message:'Please provide plan name'}]}>
                                                <CustomInput height="55px" width="295px"
                                                    onChange={(e) => { setData({ ...data, plan_name: e.target.value }) }} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='form-label-newplan'>Base Rate</div>
                                        <div className='mt-1'>
                                            <Form.Item name='planBaseRate' rules={[{required:true, message:'Please Provide Plan Rate'},{pattern:/^\d+$/, message:"Invalid Rate"}]}>
                                                <CustomInput height="55px" width="295px"
                                                    onChange={(e) => { setData({ ...data, plan_base_rate: e.target.value }) }} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='form-label-newplan'>Offer Date</div>
                                        <div className='mt-1'>
                                            <Form.Item name='planDate' rules={[{required:true, message:'Please provide a date'}]} >
                                                <DatePicker style={{ height: "55px", width: "295px", backgroundColor: "#F2F2F2", border: "none", borderRadius: "6px" }}
                                                    onChange={(e) => { setData({ ...data, plan_date: e }) }} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='form-label-newplan'>Duration in Days</div>
                                        <div className='mt-1'>

                                            <Form.Item name='planDuration' rules={[{required:true, message:'Please provide plan duration'},{pattern:/^\d+$/,message:'Please provide plan duration in days'}, { validator: validatePlanDuration }]}>
                                                <CustomInput height="55px" width="295px"
                                                    onChange={(e) => { setData({ ...data, plan_duration: e.target.value }) }} />
                                            </Form.Item>

                                        </div>
                                    </div>
                                </div>
                                {/* <div>
                                    <div className='create-newplan-heading2'>Features</div>
                                </div> */}
                                {/* <div>
                                    <div className='mt-3'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                         
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <div className='form-label-newplan'>Option for Initial Message</div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className='form-label-newplan'>:</div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div>
                                                            
                                                            <Form.Item name='planFeatureIntitialMessageCount' rules={[{required:true, message:'Please provide message count'},{pattern:/^\d+$/,message:'Inavlid count'}]} >
                                                            <Input style={{ height: "55px", width: "190px", backgroundColor: "#F2F2F2", border: "none", borderRadius: "6px" }}
                                                            onChange={(e)=>{setData({...data,planFeatureIntitialMessageCount:e.target.value})}} />
                                                            </Form.Item>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                        
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <div className='form-label-newplan'>Number of Super like Allowed</div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className='form-label-newplan'>:</div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div>
                                                        <Form.Item name='planFeatureSuperLikeCount'  rules={[{required:true, message:'Please provide superlike count'},{pattern:/^\d+$/,message:'Inavlid count'}]}>
                                                            <Input style={{ height: "55px", width: "190px", backgroundColor: "#F2F2F2", border: "none", borderRadius: "6px" }}
                                                            onChange={(e)=>{setData({...data,planFeatureSuperLikeCount:e.target.value})}} />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <div className='row'>
                                            <div className='col-md-6'>
        
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <div className='form-label-newplan'>Number of chat allowed</div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className='form-label-newplan'>:</div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div>
                                                        <Form.Item name='planFeatureChatCount'  rules={[{required:true, message:'Please provide chat count'},{pattern:/^\d+$/,message:'Inavlid count'}]}>
                                                            <Input style={{ height: "55px", width: "190px", backgroundColor: "#F2F2F2", border: "none", borderRadius: "6px" }}
                                                            onChange={(e)=>{setData({...data,planFeatureChatCount:e.target.value})}} />
                                                            </Form.Item>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                        
                                                <div className="row">
                                                    <div className='col-md-5'>
                                                        <div className='form-label-newplan'>Option to View Profile</div>
                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className='form-label-newplan'>:</div>
                                                    </div>
                                                    <div className='col-md-5'>
                                                        <div>
                                                        <Form.Item name='planFeatureOptionToViewProfile'  rules={[{required:true, message:'Please provide option to view profile count'},{pattern:/^\d+$/,message:'Inavlid count'}]}>
                                                            <Input style={{ height: "55px", width: "190px", backgroundColor: "#F2F2F2", border: "none", borderRadius: "6px" }}
                                                            onChange={(e)=>{setData({...data,planFeatureOptionToViewProfile:e.target.value})}} />
                                                            </Form.Item>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                            

                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <div className='form-label-newplan'>Option to Gift</div>

                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className='form-label-newplan'>:</div>

                                                    </div>
                                                    <div className="col-md-5">
                                                        <div>
                                                        <Form.Item name='planFeatureFriendGiftCount'  rules={[{required:true, message:'Please provide gift count'},{pattern:/^\d+$/,message:'Inavlid count'}]}>
                                                            <Input style={{ height: "55px", width: "190px", backgroundColor: "#F2F2F2", border: "none", borderRadius: "6px" }}
                                                            onChange={(e)=>{setData({...data,planFeatureFriendGiftCount:e.target.value})}} />
                                                            </Form.Item>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                            
                                                <div className="row">
                                                    <div className='col-md-5'>
                                                        <div className='form-label-newplan'>Allowed number of connect</div>

                                                    </div>
                                                    <div className='col-md-2'>
                                                        <div className='form-label-newplan'>:</div>

                                                    </div>
                                                    <div className='col-md-5'>
                                                        <div>
                                                        <Form.Item name='planFeatureAllowedNumberOfConnect'  rules={[{required:true, message:'Please provide allowed connection count'},{pattern:/^\d+$/,message:'Inavlid count'}]}>
                                                            <Input style={{ height: "55px", width: "190px", backgroundColor: "#F2F2F2", border: "none", borderRadius: "6px" }}
                                                            onChange={(e)=>{setData({...data,planFeatureAllowedNumberOfConnect:e.target.value})}} />
                                                            </Form.Item>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-4'>
             
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="row">
                                                    <div className="col-md-5">
                                                        <div className='form-label-newplan'>Day planning</div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className='form-label-newplan'>:</div>
                                                    </div>
                                                    <div className="col-md-5">
                                                        <div>
                                                        <Form.Item name='planFeatureDayPlanning'  rules={[{required:true, message:'Please provide day planning '}]}>
                                                            <Input style={{ height: "55px", width: "190px", backgroundColor: "#F2F2F2", border: "none", borderRadius: "6px" }}
                                                            onChange={(e)=>{setData({...data,planFeatureDayPlanning:e.target.value})}} />
                                                            </Form.Item>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6"></div>

                                        </div>
                                    </div>
                                </div> */}
                                {/* <div className='mt-5'>
                                    <div className="d-flex justify-content-center">
                                        <div className='d-flex justify-content-center add-feature-button-container' onClick={() => { setShowAdFeatureModal(true) }}>
                                            <div> <Button icon={<BsPlus style={{ color: "#C62137", height: "21px", width: "21px" }} />} className='add-feature-button' /></div>
                                            <div className='add-feature-button-text ms-2'>Add Feature</div>
                                        </div>

                                    </div>

                                </div> */}
                                <div className='mt-5'>
                                    <div className="d-flex justify-content-center">
                                        <div className='me-4'>
                                            <CustomButton buttonText="Save" type="save" width="179px" height="49px" htmlType="submit"/>
                                        </div>
                                        <div>
                                            <CustomButton buttonText="Cancel" type="cancel" onClick={handleCancle} width="179px" height="49px" />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Form>
                    </div>
                </div>
                <CustomModal open={showAdFeatureModal} title="Add Feature" onCancel={() => { setShowAdFeatureModal(false) }}>
                    <Form>
                        <div>
                            <div className='form-label-newplan'>New Feature</div>

                            <div>
                                <Form.Item>
                                    <CustomInput />
                                </Form.Item>
                            </div>
                        </div>
                        <div>
                            <div className='form-label-newplan'>Value</div>
                            <div>
                                <Form.Item>
                                    <CustomInput />
                                </Form.Item>
                            </div>
                        </div>
                        <div className='mt-4'>
                            <CustomButton buttonText="Save" type="save" htmlType="submit" />
                        </div>
                    </Form>
                </CustomModal>
            </div>
        </>
    )
}

export default EditNewPlan;