export const LOGIN_API="/v1/admin/auth/login";
export const LOGOUT_API="/v1/admin/auth/logout";
export const FORGOT_PASSWORD_API="/v1/admin/auth/forgot-password";
export const RESET_PASSWORD_API = "/v1/admin/auth/reset-password";

/*Roles API start*/

export const ADD_ROLES_API="/v1/admin/roles/add-roles";
export const EDIT_ROLES_API="/v1/admin/roles/edit-roles";
export const DELETE_ROLES_API="/v1/admin/roles/delete-roles";
export const GET_ROLES_API="/v1/admin/roles/list-roles"

/* Roles API end */

/* Hobbies API start */

export const ADD_HOBBY_API="/v1/admin/hobbies/add-hobby";
export const UPDATE_HOBBY_API="/v1/admin/hobbies/update-hobby";
export const DELETE_HOBBY_API="/v1/admin/hobbies/delete-hobby";
export const GET_HOBBIES_API="/v1/admin/hobbies/list-hobbies";

/* Hobbies API end */

/* Admin User API start */
export const CREATE_ADMIN_USER_API="/v1/admin/users/add-users";
export const GET_ADMIN_USERS_API="/v1/admin/users/list-users";
export const GET_SINGLE_ADMIN_USER_API="/v1/admin/users/single-user-data";
export const DELETE_ADMIN_USER_API="/v1/admin/users/delete-users";
export const EDIT_ADMIN_USER_API="/v1/admin/users/edit-users";
/* Admin User API end */

/* Campaign management Api start */

export const LIST_CAMPAIGNS_API="/v1/admin/campaign-master/list-campaigns";
export const ADD_ENABLER_AD_API="/v1/admin/campaign-master/create_enablerad";
export const ADD_NEWUSER_CAMPAIGN_API="/v1/admin/campaign-master/create-new_users-campaign"
export const DELETE_CAMPAIGN_API="/v1/admin/campaign-master/delete-campaigns";
export const START_CAMPAIGN_API="/v1/admin/campaign-master/start-campaigns";
export const STOP_CAMPAIGN_API="/v1/admin/campaign-master/stop-campaigns";
export const CREATE_SUBSCRIPTION_PLAN_API="/v1/admin/campaign-master/create-subscription_plan-campaign";
/* Campaign management Api end */


/* Subscription managment API start */
export const LIST_SUBSCRIPTION_API = '/v1/admin/plan-management/list-plans'
export const CREATE_SUBSCRIPTION_MANAGMENT_API = '/v1/admin/plan-management/create-plans';
export const EDIT_SUBSCRIPTION_MANAGMENT_API = '/v1/admin/plan-management/update-plans';
export const DELETE_SUBSCRIPTION_MANAGMENT_API = '/v1/admin/plan-management/delete-plans';


/* Subscription managment API end */


/*version managment API start */
export const CREATE_NEW_VERSION_API = '/v1/admin/mobile-app-version/create-mobile-app-version';
export const GET_VERSION_API = '/v1/admin/mobile-app-version/list-mobile-app-version';
export const LIST_ANDROID_VERSION_API = '/v1/admin/mobile-app-version/list-android-version';
export const LIST_IOS_VERSION_API =  '/v1/admin/mobile-app-version/list-ios-version';
/*version managment API end */

/*=== Terms and conditions start ===*/
export const CREATE_TERMS_AND_CONDITION_API = '/v1/admin/terms-and-conditions/create-terms-and-conditions'
export const GET_TERMS_AND_CONDITION_API = '/v1/admin/terms-and-conditions/get-terms-and-conditions'
/*=== Terms and conditions end ===*/


/*===== PRIVACY & POLICY ======*/
export const CREATE_PRIVACY_AND_POLICY_API ='/v1/admin/privacy-policy/create-privacy-policy'
export const GET_PRIVACY_AND_POLICY_API  = '/v1/admin/privacy-policy/get-privacy-policy'

/*===== PRIVACY & POLICY ======*/

/** ====REFUND POLICY START */

export const CREATE_REFUND_POLICY_API = '/v1/admin/refund-policy/create-refund-policy';
export const GET_REFUND_POLICY_API = '/v1/admin/privacy-policy/get-refund-policy'
/** ====REFUND POLICY END */


/*======REPORTS START======*/
export const GET_REPORTS_API = '/v1/admin/reports/get_reports'
/*======REPORTS END======*/