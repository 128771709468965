import React, { useEffect, useState } from 'react'
import CustomInput from '../../../components/Admin/CustomInput'
import { Form, Select,Input, message } from 'antd'
import CustomButton from '../../../components/Admin/CustomButton';
import { useLocation, useNavigate } from 'react-router-dom';
import AxiosInstance from '../../../utils/AxiosInstance';
import { EDIT_ADMIN_USER_API, GET_ROLES_API, GET_SINGLE_ADMIN_USER_API } from '../../../Api/Api';
const { TextArea } = Input;

const UserManagementEditUser = () => {
    const location = useLocation();
    const id = location.pathname.split('/').pop();
    const [editUserForm]=Form.useForm();
    const [roles,setRoles]=useState([]);
    const [initialRole,setInitialRole]=useState("");
    const navigate=useNavigate();
    const [hasError, setHasError] = useState(false);
    const [editUserData,setEditUserData]=useState({
        name:"",
        role:"",
        email:"",
        mobile:"",
        description:""
    });
    const editUser=async()=>{
       console.log("edit user data",editUserData);
       try {
        const response=await AxiosInstance.post(EDIT_ADMIN_USER_API,{         
                id:parseInt(id),
                name: editUserData.name,
                roleId: editUserData.role,
                email: editUserData.email,
                countryCode: "+91",
                mobileNumber:editUserData.mobile,
                description: editUserData.description
        });
        if(response.status===200){
            message.success("user edited");
            navigate("/admin/user_management_user")
        }
       } catch (error) {
        message.error(error.response.data.message)
       }
    }
    const getAdminUserDetails=async()=>{
        try {
            const response=await AxiosInstance.post(GET_SINGLE_ADMIN_USER_API,
                {
                    id:parseInt(id) 
                });
         console.log("here is the response",response);
         if(response.status===200){
            setInitialRole(response.data[0].roles.role_name);
            setEditUserData({
                name:response.data[0].name,               
                email:response.data[0].email,
                mobile:response.data[0].phone_number,
                role:response.data[0].roles.id,
                description:response.data[0].description
            })
            editUserForm.setFieldsValue({
                name:response.data[0].name,
                //role:response.data[0].roles.id,
                email:response.data[0].email,
                mobile:response.data[0].phone_number,
                description:response.data[0].description

            })
         }       
        } catch (error) {
            message.error("error fetching data");
            console.log("error",error);
        }
    }
    const getRoles=async()=>{
        try {
            const response=await AxiosInstance.get(GET_ROLES_API);
            if(response.status===200){
                const roles_temp=response?.data?.map((item)=>{
                    return { value:item.id,label:item.role_name}
                  });
                  setRoles(roles_temp);
            }
        } catch (error) {
            message.error("error fetching data");
            console.log("error",error);
        }
    }


   

  const validateMobileNumber = (_, value) => {
    // The regular expression to check if the number is "0000000000"
    const regex = /^0{10}$/;
    if (regex.test(value)) {
      setHasError(true);
      return Promise.reject(new Error('Invalid mobile number'));
    } else {
      setHasError(false);
      return Promise.resolve();
    }
  };

    const handleCancel = ()=>{
     
      navigate('/admin/user_management_user')
    }
    useEffect(()=>{
        getRoles();
        getAdminUserDetails();
        
    },[])
  return (
    <>
        <div className='pt-4 ps-5 pe-5'>
            
      <div>
        <Form onFinish={editUser} form={editUserForm}
        
         >
          <div className="d-flex justify-content-between">
            <div>
              <div className='form_label'>Name</div>
              <div className='mt-1'>
              <Form.Item name="name" rules={[{required:true,message:"Please Enter Name"},{pattern:/^(?!^\s+$)[a-zA-Z\s]+$/,message:"invalid name"},{max:50,message:'Name can not be more than 50 characters '}]}>
              <CustomInput height="54px" onChange={(e)=>{setEditUserData({...editUserData,name:e.target.value})}}/>
              </Form.Item>    
              </div>
          
            </div>
            <div>
              <div className='form_label' >Role</div>
              <div className='mt-1'>
              <Form.Item name="role" >
                <div className='select_wrapper'>
                <Select
               // defaultValue={initialRole}
                value={initialRole}
                style={{
                 backgroundColor:"#F1F1F1",
                    width:"298px"
                }}
                onChange={(e)=>{
                    setInitialRole(e);
                    setEditUserData({...editUserData,role:e})
                }}
                options={roles}
              />
                </div>
    
              </Form.Item>    
              </div>
            </div>
            <div>
              <div className='form_label'>Email ID</div>
              <div className='mt-1'>
              <Form.Item name="email" rules={[{required:true,message:"Please enter an email id"},{pattern:/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,message:"enter a valid email address"}]}>
              <CustomInput height="54px" onChange={(e)=>{
                setEditUserData({...editUserData,email:e.target.value})
              }}/>
              </Form.Item>    
              </div>
            </div>
            <div>
              <div className='form_label'> Mobile Number</div>
              <div className='mt-1'>
              <Form.Item name="mobile" rules={[{required:true,message,message:"Please enter mobile number"},{pattern:/^[0-9]{10}$/,message:"enter a valid mobile number"},{ validator: validateMobileNumber }]}
               validateStatus={hasError ? 'error' : ''}
               help={hasError ? 'Invalid mobile number' : null}>
              <CustomInput height="54px" onChange={(e)=>{
                setEditUserData({...editUserData,mobile:e.target.value})
              }}/>
              </Form.Item>    
              </div>
            </div>
          </div>
          <div>
            <div className='form_label'>Descriptions</div>
            <div className='mt-1'>
            <Form.Item name="description" rules={[{required:true,message:"Please enter description"},{pattern:/^(?!^\s+$)[a-zA-Z\s]+$/,message:"invalid name"}]}>
              <TextArea rows={4} style={{backgroundColor:"#F1F1F1",border:"none"}} onChange={(e)=>{
                setEditUserData({...editUserData,description:e.target.value})
              }}/>
            </Form.Item>
            </div>

          </div>
          <div className='mt-4'>
            <div className="d-flex justify-content-center ">
              <div className='me-4'>
                <CustomButton buttonText="save" type="save" height="49px" width="179px" htmlType="submit"/>
              </div>
              <div>
                <CustomButton buttonText="cancel"  type="cancel" onClick={handleCancel} height="49px" width="179px"/>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
    </>
  )
}

export default UserManagementEditUser