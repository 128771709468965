import { Card, Progress, Select } from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';
import {RiseOutlined} from '@ant-design/icons'
import './Users.scss'

const Users = () => {

    const selectData = ["last Month", "last 3month"]
    const {Option}  = Select;

    const totalUsersValue = 721000;
    const otherCardValue = 521000;
    const totalValue = totalUsersValue + otherCardValue;
  
    // Calculate the percentage based on the values
    const totalPercentage = (totalUsersValue / totalValue) * 100;
    const otherCardPercentage = (otherCardValue / totalValue) * 100;
  
    // Define the gradient colors
    const gradientColors = `linear-gradient(to right, #FFECCF ${totalPercentage}%, #FFCFCF ${totalPercentage}%, #FFCFCF ${totalPercentage + otherCardPercentage}%, #FFECCF ${totalPercentage + otherCardPercentage}%)`;


    return (
       <>
        <div>
            <span style={{font:'lato',fontWeight:'500',fontSize:'25px',fontStyle:'italic',lineHeight:'30px'}}>Users</span>
        <div
      style={{
        width: '488px',
        height: '6px',
        borderRadius: '19px',
        background: gradientColors,
        marginBottom:'5px'
      }}
    />
       <div style={{display:'flex',gap:'20px'}}>
       
      
       <Card style={{width:'236.49px',height:'125.58px',backgroundColor:"#FFECCF",borderRadius:'18px'}}>
        
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
         
        <Title style={{ fontFamily: 'lato', lineHeight: '22.42px', fontSize: '15.7px', fontWeight: '600' }}>Total-Users</Title>
          <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}>
                <span style={{fontFamily: 'lato', lineHeight: '40.36px', fontSize: '26.91px', fontWeight: '700'}}>721k</span>
                <span style={{fontFamily: 'lato', lineHeight: '20.18px', fontSize: '13.45px', fontWeight: '400',paddingLeft:'30px'}}>11.1% <RiseOutlined /></span>
          </div>
        </div>
            
        </Card>
       <Card style={{width:'236.49px',height:'125.58px',backgroundColor:"#FFCFCF",borderRadius:'18px'}}>
        
        <div  className='select_wrapp' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' , marginTop: '-5px'}}>
        <div style={{display:'flex',flexDirection:'row'}}>
        <Select
        
        defaultValue={'Last Month'}>
        {selectData?.map((item) => (
        <Option key={item} value={item}>
          {item}
        </Option>
      ))}
        </Select>
        <span style={{fontFamily: 'lato', lineHeight: '22.42px', fontSize: '13px', fontWeight: '400'}}>(Total Users)</span>
        </div>
       
          <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}>
                <span style={{fontFamily: 'lato', lineHeight: '40.36px', fontSize: '26.91px', fontWeight: '700',paddingLeft:'15px'}}>521k</span>
                
          </div>
        </div>
            
        </Card>
       </div>
            </div>
       
       </>
    );
}

export default Users;
