import React, { useEffect, useState } from 'react'
import CustomTable from '../../../components/Admin/CustomTable';
import { DatePicker, Input, Select, message } from 'antd';
import { BiFilter } from 'react-icons/bi';
import './Reports.scss'
import AxiosInstance from '../../../utils/AxiosInstance';
import { GET_REPORTS_API } from '../../../Api/Api';
const Reports = () => {
  const [data, setData] = useState([]);
  const [filterData, setFilterdata] = useState({
    fromDate: "",
    toDate: "",
    gender: "",
    city: "",
    version: "",
    minAge: "",
    maxAge: ""
  })


  const getData = () => {
    AxiosInstance.post(GET_REPORTS_API, 
     filterData
    ).then((res) => {
      if (res?.status === 200) {
        setData(res?.data)
      }
    }).catch((err) => {
      message.error('Something went wrong');
      console.log('err in fetching report is', err);
    })
  }

  const getDatafilter = (fromDate,toDate,gender,city,version,minAge,maxAge)=>{
    AxiosInstance.post(GET_REPORTS_API, {
      fromDate: fromDate,
      toDate: toDate ,
      gender: gender,
      city: city,
      version: version,
      minAge: minAge,
      maxAge:maxAge
    }).then((res) => {
      if (res?.status === 200) {
        setData(res?.data)
      }
    }).catch((err) => {
      message.error('Something went wrong');
      console.log('err in fetching report is', err);
    })
  }

  // const handleFilter = (fromdate, toDate, city) => {
  //   if(fromdate){
  //     console.log("fromdate:", fromdate);
  //   }
  
  //   setFilterdata({
  //     fromDate: fromdate,
  //     toDate: toDate,
  //     gender: "",
  //     city: city,
  //     version: "",
  //     minAge: "",
  //     maxAge: ""
  //   })
  //   getDatafilter(fromdate,toDate,city) 
  // }

  // useEffect(() => {
  //   getData()
  // }, [])

  const handleFilter = (fieldName, value) => {
    console.log('fffffffff',fieldName);
    setFilterdata((prevFilterData) => ({
      ...prevFilterData,
      [fieldName]: value
    }));
  };

  useEffect(() => {
    getData();
  }, [filterData]);

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",

    },
    {
      title: "Age",
      dataIndex: "age",
      key: "age",

    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",

    },
    {
      title: "Marital status",
      dataIndex: "maritalStatus",
      key: "maritalStatus",

    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",

    },
    {
      title: "Last Login Date",
      dataIndex: "last_login",
      key: "last_login",

    },
    {
      title: "Mobile OS & Version",
      dataIndex: "mobileOsVersion",
      key: "mobileOsVersion",
    },
    {
      title: "App Version",
      dataIndex: "appVersion",
      key: "appVersion",
    },
    {
      title: "Top Ups",
      dataIndex: "topUps",
      key: "topUps",
    },
    {
      title: "Sub Plan Total",
      dataIndex: "subscriptionPlanTotal",
      key: "subscriptionPlanTotal",
    },
    {
      title: "Total (Sum of Topup + Sub)",
      dataIndex: "total",
      key: "total",
    },
  ];

  return (
    <>
      <div className='pt-4 ps-5 pe-5'>
        <div>
          <div className='heading'>Reports</div>
        </div>
        <div className='mt-4'>
          <div className='d-flex'>
            <div>
              <BiFilter style={{ height: "23px", width: "24px" }} />
            </div>
            <div className='ms-3'>
              <div className="date_wrapper1">
              <DatePicker placeholder='Date From'  onChange={(date) => handleFilter('fromDate',date)} />
              </div>
            </div>
            <div className='ms-3'>
              <div className="date_wrapper1">
                <DatePicker placeholder='Date To'
                  onChange={(e) => { (handleFilter('toDate',e)) }} />
              </div>

            </div>
            <div className='ms-3'>
              <div className='select_wrapper1'>
                <Select
                  onChange={(e)=>handleFilter('gender',e)}
                  placeholder="Gender" 
                  options={[
                    {
                      value: 'MALE',
                      label: 'Male',
                    },
                    {
                      value: 'FEMALE',
                      label: 'Female',
                    },
                    {
                      value: '',
                      label: 'Others',
                    },

                  ]} />
              </div>

            </div>
            <div className='ms-3'>
              <div className="select_Input_wrapper">
                <Input placeholder='City'
                 onChange={(e) => handleFilter('city',e.target.value) } />
              </div>

            </div>
            <div className='ms-3'>
              <div className="select_wrapper1">
                <Select placeholder="Age Group" options={[
                  {
                    value: 'jack',
                    label: 'Above 60',
                  },
                  {
                    value: 'lucy',
                    label: 'Between 50 to 60',
                  },
                  {
                    value: 'Yiminghe',
                    label: 'Between 40 to 50',
                  },
                  {
                    value: 'Yiminghe',
                    label: 'Between 30 to 40',
                  },
                  {
                    value: 'Yiminghe',
                    label: 'Between 20 to 30',
                  },
                  {
                    value: 'Yiminghe',
                    label: 'Below 20',
                  },

                ]} />
              </div>

            </div>
            <div className='ms-3'>
              <div className="select_wrapper1">
                <Select placeholder="Mobile OS" options={[
                  {
                    value: 'jack',
                    label: 'Jack',
                  },
                  {
                    value: 'lucy',
                    label: 'Lucy',
                  },
                 

                ]} />
              </div>

            </div>
          </div>
        </div>
        <div className='mt-5'>
          <div><CustomTable dataSource={data} columns={columns} /></div>
        </div>
      </div>
    </>
  )
}

export default Reports