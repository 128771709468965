import React from 'react';
import './Users.scss'
import { Card, Select } from 'antd';

const Revenue = () => {

    const selectData = ["last Month", "last 3month"]
    const {Option}  = Select;

    const totalUsersValue = 321000;
    const otherCardValue = 521000;
    const totalValue = totalUsersValue + otherCardValue;
  
    // Calculate the percentage based on the values
    const totalPercentage = (totalUsersValue / totalValue) * 100;
    const otherCardPercentage = (otherCardValue / totalValue) * 100;
  
    // Define the gradient colors
    const gradientColors = `linear-gradient(to right, #D0CFFF ${totalPercentage}%, #DEFFCF ${totalPercentage}%, #DEFFCF ${totalPercentage + otherCardPercentage}%, #D0CFFF ${totalPercentage + otherCardPercentage}%)`;
    return (
      <>
      <div>
      <span style={{font:'lato',fontWeight:'500',fontSize:'25px',fontStyle:'italic',lineHeight:'30px'}}>Revenue</span>
        <div
      style={{
        width: '670px',
        height: '6px',
        borderRadius: '19px',
        background: gradientColors,
        marginBottom:'5px'
      }}
    />
       <div style={{display:'flex',gap:'20px'}}>
       <Card style={{width:'322.91px',height:'125.58px',backgroundColor:"#D0CFFF",borderRadius:'18px'}}>
        
        <div  className='select_wrapper_users' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' , marginTop: '-5px'}}>
        <div style={{display:'flex',flexDirection:'row'}}>
        <Select
        
        defaultValue={'Last Month'}>
        {selectData?.map((item) => (
        <Option key={item} value={item}>
          {item}
        </Option>
      ))}
        </Select>
        <span style={{fontFamily: 'lato', lineHeight: '22.42px', fontSize: '13px', fontWeight: '400'}}>(Revenue through users)</span>
        </div>
       
          <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}>
                <span style={{fontFamily: 'lato', lineHeight: '40.36px', fontSize: '26.91px', fontWeight: '700',paddingLeft:'15px'}}>$521k</span>
                
          </div>
        </div>
            
        </Card>
     
       
       <Card style={{width:'322.91px',height:'125.58px',backgroundColor:"#DEFFCF",borderRadius:'18px'}}>
        
        <div  className='select_wrapper_enablers' style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' , marginTop: '-5px'}}>
        <div style={{display:'flex',flexDirection:'row'}}>
        <Select
        
        defaultValue={'Last Month'}>
        {selectData?.map((item) => (
        <Option key={item} value={item}>
          {item}
        </Option>
      ))}
        </Select>
        <span style={{fontFamily: 'lato', lineHeight: '22.42px', fontSize: '13px', fontWeight: '400'}}>(Revenue through enablers)</span>
        </div>
       
          <div style={{  display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black' }}>
                <span style={{fontFamily: 'lato', lineHeight: '40.36px', fontSize: '26.91px', fontWeight: '700',paddingLeft:'15px'}}>$521k</span>
                
          </div>
        </div>
            
        </Card>
       </div>
            </div>
       
      </>
    );
}

export default Revenue;
