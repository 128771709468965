import React from 'react'
import UsersGraph from '../../../components/Admin/Dashboard/UsersGraph'
import TotalCoinsale from '../../../components/Admin/Dashboard/TotalCoinsale'
import TransactionTable from '../../../components/Admin/Dashboard/TransactionTable'
import SubplanPiechart from '../../../components/Admin/Dashboard/SubplanPiechart'
import ActiveUsers from '../../../components/Admin/Dashboard/ActiveUsers'
import Users from '../../../components/Admin/Dashboard/Users'
import Revenue from '../../../components/Admin/Dashboard/Revenue'

const Dashboard = () => {
  return (
    <>     
    <div style={{font:'lato',fontWeight:"400", fontSize:'40px',lineHeight:'48px',paddingLeft:'20px',paddingBottom:"20px",paddingTop:'10px'}}>
      DashBoard
    </div>
    <div style={{marginTop:'20px',paddingLeft:'50px', paddingRight:'40px',display:'flex',}}>
    <Users/>
    <div style={{paddingLeft:'30px'}}>
    <Revenue/>
    </div>
    
    </div>
    
    <div style={{marginTop:'52px',paddingLeft:'50px',display:'flex',flexWrap:'wrap',gap:'20px'}}>
      <UsersGraph/>
      <TotalCoinsale/>
      <TransactionTable/>
      <SubplanPiechart/>
    </div>
    <div style={{marginTop:'20px',paddingLeft:'50px', paddingRight:'40px'}}>
    <ActiveUsers/>
    </div>
   
  
    </>

  )
}

export default Dashboard