import React, { useEffect, useState } from 'react'
import CustomButtonGroup from '../../../components/Admin/CustomButtonGroup'
import { Button, DatePicker, Form, Input, Upload, Checkbox, TimePicker, Switch, message } from 'antd'
import CustomButton from '../../../components/Admin/CustomButton';
import './EnablerAd.scss'
import CustomDatePicker from '../../../components/Admin/CustomDatePicker';
import AxiosInstance from '../../../utils/AxiosInstance';
import { CREATE_SUBSCRIPTION_PLAN_API } from '../../../Api/Api';
import { useNavigate } from 'react-router-dom';
import CustomTimePicker from '../../../components/Admin/CustomTimePicker';
import './ExistingUsers.scss'
const { Dragger } = Upload;
const { TextArea } = Input;

const ExistingUsers = () => {
  const [AddForm] = Form.useForm()
  const navigate = useNavigate();
  const [subscriptionPlanData, setSubscriptionPlanData] = useState({
    schemeName: "",
    description: "",
    startDate: "",
    startTime: "",
    endTime: "",
    endDate: "",
    forNewUsers: 0,
    forExistingUsers: 0,
    offerValue: "",
    offerType: "PERCENTAGE",
    imageUrl: ""
  });

  /////used to set disable the date in date picker
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);


  const handleReset = () => {
    AddForm.resetFields()
    setFromDate(null)
    setToDate(null)
    setSubscriptionPlanData({
      schemeName: "",
      description: "",
      startDate: "",
      startTime: "",
      endTime: "",
      endDate: "",
      forNewUsers: 0,
      forExistingUsers: 0,
      offerValue: "",
      offerType: "PERCENTAGE",
      imageUrl: ""
    })
    navigate("/admin/campaign_management")
  }





  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };



  const disabledFromDate = (currentDate) => {
    // Disable dates after the selected "To" date in the "From" date picker
    if (toDate && currentDate && currentDate >= toDate) {
      return true;
    }

    return false;
  };

  const disabledToDate = (currentDate) => {
    // Disable dates before the selected "From" date in the "To" date picker
    if (fromDate && currentDate && currentDate <= fromDate) {
      return true;
    }

    return false;
  };



  const [temporaryOfferValue, setTemporaryOfferValue] = useState("");

  const changeOfferValue = (newOfferType) => {
    // Reset offerValue to temporaryOfferValue and clear the input field
    setSubscriptionPlanData({
      ...subscriptionPlanData,
      offerValue: newOfferType === "PERCENTAGE" ? temporaryOfferValue : "",
      offerType: newOfferType,
    });
    AddForm.setFieldsValue({ offerValue: "" });
    setTemporaryOfferValue("");
  };

  const validateFileSize = (file) => {
    const maxSize = 10 * 1024 * 1024; // 10 MB (adjust the size limit as needed)
    if (file.size > maxSize) {
      message.error('File size must be within 10MB.');
      return false; // Prevent file from being uploaded
    }
    return true; // Proceed with the upload
  };

  
  const validateOfferValue = (_, value) => {
    if (subscriptionPlanData.offerType === 'PERCENTAGE' && Number(value) > 100) {
      return Promise.reject('Value cannot be greater than 100%');
    }
    return Promise.resolve();
  };

  const addSubscriptionPlan = async () => {

    if (subscriptionPlanData.forExistingUsers !== 0 || subscriptionPlanData.forNewUsers !== 0) {
      let timefromDateObj = new Date(subscriptionPlanData.startTime);
      let timetoDateObj = new Date(subscriptionPlanData.endTime);
      let datefromDateObj = new Date(subscriptionPlanData.startDate);
      let datetoDateObj = new Date(subscriptionPlanData.endDate);

      function extractDate(dateObj) {
        const day = dateObj.getUTCDate();
        const month = dateObj.toLocaleString('default', { month: 'short' });
        const year = dateObj.getUTCFullYear();
      
      
        return { day, month, year };
      }
      
      const extractedDate1 = extractDate(datefromDateObj);
      const extractedDate2 = extractDate(datetoDateObj);
  
      if (extractedDate1.year === extractedDate2.year &&
        extractedDate1.month === extractedDate2.month &&
        extractedDate1.day === extractedDate2.day) {
          if(timefromDateObj >= timetoDateObj){
            message.error('Offer Period Time to can not be lesserthan Offer Period Time From ')
          }else{
            datefromDateObj.setHours(timefromDateObj.getHours());
      datefromDateObj.setMinutes(timefromDateObj.getMinutes());
      datefromDateObj.setSeconds(timefromDateObj.getSeconds());
      datetoDateObj.setHours(timetoDateObj.getHours());
      datetoDateObj.setMinutes(timetoDateObj.getMinutes());
      datetoDateObj.setSeconds(timetoDateObj.getSeconds());
     
      try {
        const formData = new FormData();
        formData.append("schemeName", subscriptionPlanData.schemeName);
        formData.append("description", subscriptionPlanData.description);
        formData.append("startDate", subscriptionPlanData.startDate);
        formData.append("endDate", subscriptionPlanData.endDate);
        formData.append("forNewUsers", subscriptionPlanData.forNewUsers);
        formData.append("forExistingUsers", subscriptionPlanData.forExistingUsers);
        formData.append("offerValue", subscriptionPlanData.offerValue);
        formData.append("OfferType", subscriptionPlanData.offerType);
        formData.append("imageUrl", subscriptionPlanData.imageUrl);
        const response = await AxiosInstance.post(CREATE_SUBSCRIPTION_PLAN_API, formData);
        if (response.status === 201) {
          message.success("campaign created");
          navigate("/admin/campaign_management")
        }
      } catch (error) {
        message.error(error.response.data.message);
      }
          }
        }else{
          datefromDateObj.setHours(timefromDateObj.getHours());
      datefromDateObj.setMinutes(timefromDateObj.getMinutes());
      datefromDateObj.setSeconds(timefromDateObj.getSeconds());
      datetoDateObj.setHours(timetoDateObj.getHours());
      datetoDateObj.setMinutes(timetoDateObj.getMinutes());
      datetoDateObj.setSeconds(timetoDateObj.getSeconds());
     
      try {
        const formData = new FormData();
        formData.append("schemeName", subscriptionPlanData.schemeName);
        formData.append("description", subscriptionPlanData.description);
        formData.append("startDate", subscriptionPlanData.startDate);
        formData.append("endDate", subscriptionPlanData.endDate);
        formData.append("forNewUsers", subscriptionPlanData.forNewUsers);
        formData.append("forExistingUsers", subscriptionPlanData.forExistingUsers);
        formData.append("offerValue", subscriptionPlanData.offerValue);
        formData.append("OfferType", subscriptionPlanData.offerType);
        formData.append("imageUrl", subscriptionPlanData.imageUrl);
        const response = await AxiosInstance.post(CREATE_SUBSCRIPTION_PLAN_API, formData);
        if (response.status === 201) {
          message.success("campaign created");
          navigate("/admin/campaign_management")
        }
      } catch (error) {
        message.error(error.response.data.message);
      }
        }



      
    } else {
      message.error('Please Select For Users or Existing Users')
    }

  }
  return (
    <>
      <div className='pt-4 ps-5 pe-5'>
        <div>
          <div className='heading'>Campaign Management</div>
        </div>
        <div className='mt-3'>
          <div>
            <CustomButtonGroup />
          </div>
        </div>
        <div className='mt-4'>
          <div>
            <Form
              form={AddForm}
              onFinish={addSubscriptionPlan}>
              <div>
                <div className="d-flex">
                  <div>
                    <div className="d-flex">
                      <div>
                        <Form.Item name="forNewUsers">
                          <div className='checkbox_wrapper'>
                            <Checkbox onChange={(e) => {
                              setSubscriptionPlanData({ ...subscriptionPlanData, forNewUsers: e.target.checked ? 1 : 0 })
                            }} />
                          </div>

                        </Form.Item>
                      </div>
                      <div className='ms-3 '>
                        <div className='form_label'>
                          For New Users
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='ms-3'>
                    <div className="d-flex">
                      <div>
                        <Form.Item name="forExistingUsers">
                          <div className='checkbox_wrapper'>
                            <Checkbox onChange={(e) => {
                              setSubscriptionPlanData({ ...subscriptionPlanData, forExistingUsers: e.target.checked ? 1 : 0 })
                            }} />
                          </div>

                        </Form.Item>
                      </div>
                      <div className='form_label ms-3'>For Existing Users</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div>
                  <div className='form_label'> Name of the Scheme</div>
                  <div>
                    <Form.Item name="schemaName" rules={[{ required: true, message: "Please Enter the Scheme Name" }, { pattern: /^(?!^\s+$)[a-zA-Z\s]+$/, message: "invalid name" }, { max: 50, message: 'maximum character is 50' }]}>
                      <Input style={{ backgroundColor: "#F1F1F1", height: "54px", width: "631px", borderRadius: "6px", border: "none" }} onChange={(e) => {
                        setSubscriptionPlanData({ ...subscriptionPlanData, schemeName: e.target.value })
                      }} />
                    </Form.Item>
                  </div>
                </div>
                {/* <div>
              <div className='form_label'>Activity</div>
                <div>
                  <Form.Item>
                  <Input style={{backgroundColor:"#F1F1F1",height:"54px",width:"631px",borderRadius:"6px",border:"none"}}/>
             
                  </Form.Item>
                </div>
              </div> */}
              </div>
              <div className='mt-3'>
                <div className='form_label'>Upload Offer Image</div>
                <div className='mt-1'>
                  <Form.Item name="imageUrl" rules={[{ required: true, message: 'Please upload an image' }]}>
                    <Dragger accept={['image/jpeg', 'image/jpg', 'image/png']} maxCount={1} beforeUpload={true}
                      onChange={(file) => {

                        if (validateFileSize(file.file)) { // Check the return value of validateFileSize
                          setSubscriptionPlanData({ ...subscriptionPlanData, imageUrl: file.file.originFileObj })
                        }


                      }}>
                      <p className="ant-upload-drag-icon">
                        {/* <InboxOutlined /> */}
                        <img src='/images/cloud-upload.png' />
                      </p>
                      <p className="ant-upload-text">Drag or drop files here</p>
                      <div className='upload-button-wrapper'>
                        <Button >upload Offer image</Button>
                      </div>

                    </Dragger>
                  </Form.Item></div>
              </div>
              <div className='mt-3 '>
                <div className='d-flex justify-content-between'>
                  <div>
                    <div className='form_label'>Offer Period Date From</div>
                    <div>
                      <Form.Item name="startDate" rules={[{ required: true, message: 'Please select a date' }]}>
                        <CustomDatePicker
                          disabledDate={disabledFromDate}
                          onChange={(e) => {
                            handleFromDateChange(e)
                            setSubscriptionPlanData({ ...subscriptionPlanData, startDate: e })
                          }} />
                      </Form.Item>

                    </div>
                  </div>
                  <div>
                    <div className='form_label'>Offer Period Time From</div>
                    <div>
                      <Form.Item name="startTime" rules={[{ required: true, message: 'Please select time' }]}>
                        <CustomTimePicker onChange={(e) => {

                          
                          setSubscriptionPlanData({ ...subscriptionPlanData, startTime: e })
                        }} />
                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <div className='form_label'>Offer Period Date To</div>
                    <div>
                      <Form.Item name="endDate" rules={[{ required: true, message: 'Please select a date' }]}>
                        <CustomDatePicker
                          disabledDate={disabledToDate}
                          onChange={(e) => {
                            handleToDateChange(e)
                            setSubscriptionPlanData({ ...subscriptionPlanData, endDate: e })
                          }} />
                      </Form.Item>

                    </div>
                  </div>

                  <div>
                    <div className='form_label'>Offer Period Date To</div>
                    <div>
                      <Form.Item name="endTime" rules={[{ required: true, message: 'Please select time' }]}>
                        <CustomTimePicker
                          onChange={(e) => {
                            
                            setSubscriptionPlanData({ ...subscriptionPlanData, endTime: e })
                          }} />
                      </Form.Item>
                    </div>
                  </div>
                  {/* <div>
                  <div className='form_label'>Extra Coin</div>
                  <div>
                    <Form.Item>
                      <Input style={{backgroundColor:"#F1F1F1",height:"54px",width:"185px",borderRadius:"6px",border:"none"}}/>
                    </Form.Item>
                  </div>
                </div> */}
                </div>
              </div>
              <div>
                <div className="d-flex">
                  <div>
                    <div className='form_label mt-2'>Offer on sub plan:</div>
                  </div>
                  <div className='ms-3 mt-2'>
                    <div className="d-flex">
                      {subscriptionPlanData.offerType === "PERCENTAGE" ? (<div className='form_label' style={{ color: '#c62137' }}>Percentage</div>) : (<div className='form_label'>Percentage</div>)}

                      <div className="switch-wrapper ms-2">
                        <Form.Item name="offerType" >
                          <div className='switch_wrapper'>
                            <Switch onChange={(e) => {
                            const newOfferType = e ? "FLAT" : "PERCENTAGE";
                            changeOfferValue(newOfferType);
                            
                            
                            }} />
                          </div>

                        </Form.Item>

                      </div >
                      {subscriptionPlanData.offerType === "FLAT" ? (<div className='form_label ms-2' style={{ color: '#c62137' }}>Amount</div>) : (<div className='form_label ms-2' >Amount</div>)}

                    </div>

                  </div>
                  <div>
                    
                  <div className='ms-3'>
              {subscriptionPlanData.offerType === 'PERCENTAGE' ? (
                <Form.Item name="offerValue" rules={[{ required: true, message: "Please add value" }, { pattern: /^\d+$/, message: "invalid value" },{ validator: validateOfferValue }]} >
                  <div className="input-with-suffix">
                    <Input
                      style={{
                        backgroundColor: "#F1F1F1",
                        height: "54px",
                        width: "295px",
                        borderRadius: "6px",
                        border: "none",
                        textAlign:"center"
                      }}
                      onChange={(e) => {
                        setTemporaryOfferValue(e.target.value);
                        setSubscriptionPlanData({
                          ...subscriptionPlanData,
                          offerValue: e.target.value,
                        });
                      }}
                      value={temporaryOfferValue} // Use temporaryOfferValue to display the current value
                    />
                    <span className="suffix">%</span>
                  </div>
                </Form.Item>
              ) : (
                <Form.Item name="offerValue" rules={[{ required: true, message: "Please add value" }, { pattern: /^\d+$/, message: "invalid value" }]} >
                  <div className="input-with-suffix">
                    <Input
                      style={{
                        backgroundColor: "#F1F1F1",
                        height: "54px",
                        width: "295px",
                        borderRadius: "6px",
                        border: "none",
                        textAlign:"center"
                       
                      }}
                      onChange={(e) => {
                        setTemporaryOfferValue(e.target.value);
                        setSubscriptionPlanData({
                          ...subscriptionPlanData,
                          offerValue: e.target.value,
                        });
                      }}
                      value={temporaryOfferValue} // Use temporaryOfferValue to display the current value
                    />
                    <span className="suffix">₹</span>
                  </div>
                </Form.Item>
              )}
            </div>
                  </div>
                </div>
              </div>

              <div>
                <div className='form_label'> Description</div>
                <div>
                  <Form.Item name="description" rules={[{ required: true, message: "Please write description" }, { max: 250, message: 'Maximum character allowed is 250' }, { min: 3, message: 'Minimum 3 character is required ' }]}>
                    <TextArea style={{ backgroundColor: "#F1F1F1", border: "none" }} rows={5} onChange={(e) => {
                      setSubscriptionPlanData({ ...subscriptionPlanData, description: e.target.value })
                    }} />
                  </Form.Item>
                </div>
              </div>
              <div className='d-flex justify-content-center' style={{paddingBottom:'18px'}}>
                <div className='me-2'><CustomButton type="save" buttonText="Save" width="179px" height="49px" htmlType="submit" /></div>
                <div><CustomButton onClick={handleReset} type="cancel" buttonText="Cancel" width="179px" height="49px" /></div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

export default ExistingUsers