import React, { useEffect, useState } from 'react'
import CustomAddButton from '../../../components/Admin/CustomAddButton'
import { Button, message } from 'antd';
import { RiDeleteBinLine } from "react-icons/ri";
import CustomTable from '../../../components/Admin/CustomTable';
import { useNavigate } from 'react-router-dom';
import AxiosInstance from '../../../utils/AxiosInstance';
import { DELETE_CAMPAIGN_API, LIST_CAMPAIGNS_API, START_CAMPAIGN_API, STOP_CAMPAIGN_API } from '../../../Api/Api';
import moment from 'moment';
import { getPeriod } from '../../../utils/GetDaysBetweenDates';
import { getCurrentDateStatus } from '../../../utils/GetDateStatus';
const CampaignManagement = () => {
  const navigate=useNavigate();
  const [campaigns,setCampaigns]=useState([]);
  const [reload,setReload]=useState(false);
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    
    },
    {
      title: "Date Posted",
      dataIndex: "datePosted",
      key: "datePosted",
    
    },
    {
      title: "Period",
      dataIndex: "period",
      key: "period",
    
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      key: "endDate",
    
    },
    {
      title: "Vendor",
      dataIndex: "vendors",
      key: "vendors",
    
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render:(value,index)=>{
        let textColor = 'black';
        if (value === 'Active') {
          textColor = '#760932D'; // Change text color to green for "Active" status
        } else if (value === 'Expired') {
          textColor = '#c62137'; // Change text color to red for "Inactive" status
        }else if(value === 'Pipeline'){
          textColor = '#FF8421';
        }
  
       return(
        <>
         <div style={{color:textColor}}>
          {value}
         </div>
        </>
       )
      }
    },
    {
      dataIndex: "action",
      render: (data,record) => (
       
        <>
       
          <div className="d-flex justify-content-center" >
            {record.is_campaign_running? <div className='me-1'> 
            <Button style={{backgroundColor:"#363636",color:"white",height:"32.5px",width:"108px"}} onClick={()=>{
                stopCampaign(record)
            }}>Stop</Button>

            </div>: <div className='me-2'>
            <Button style={{backgroundColor:"#60932D",color:"white",height:"32.5px",width:"108px"}} onClick={()=>{
              startCampaign(record)
            }}>Start</Button>

            </div>}
           
           
            <RiDeleteBinLine className="icons" onClick={()=>{
              deleteCampaign(record);
            }}/>
          </div>
        </>
      ),
    },
  ];
  
  const gotoEnablerAd=()=>{
    navigate("/admin/enabler_ad");
  }

  const startCampaign=async(record)=>{
    try {
      const response=await AxiosInstance.post(START_CAMPAIGN_API,{
        id:record.id
      });
      if(response.status===200){
        message.success("campaing started");
        setReload(!reload)
      }
    } catch (error) {
      message.error("some error occured while starting campaign");
    }
  }



  const stopCampaign=async(record)=>{
    try {
      const response=await AxiosInstance.post(STOP_CAMPAIGN_API,{
        id:parseInt(record.id) 
      });
      if(response.status===200){
        message.success("campaing stopped");
        setReload(!reload)
      }
    } catch (error) {
      console.log(error)
      message.error("some error occured while stopping campaign");
    }
  }

  const deleteCampaign=async(record)=>{
    try {
      const response=await AxiosInstance.post(DELETE_CAMPAIGN_API,{
        id:record.id
      });
      if(response.status===200){
        message.success("Campaign deleted");
        setReload(!reload);
      }
    } catch (error) {
      message.error("Error in deleting campaign");
    }
  }
  const getCampaigns=async()=>{
    try {
      const response=await AxiosInstance.post(LIST_CAMPAIGNS_API,
        {
          page:1,
          perPage:15
        });
        console.log("here is campaign response",response);
        if(response.status===200){
          let campaign_temp=response.data.map((item)=>{
            let campaignStatus=getCurrentDateStatus(item.start_date,item.end_date);
            console.log("campaing status",campaignStatus)
            let status="";
            if(campaignStatus==0){
              status="Pipeline"
            }else if(campaignStatus==1){
              status="Active"
            }else if(campaignStatus==2){
              status="Expired"
            }
              return {
                id:item.id,
                name:item.name,
                datePosted:moment(new Date(item.start_date)).format("DD-MM-YYYY") ,
                period:getPeriod(item.start_date,item.end_date),
                endDate:moment(new Date(item.end_date)).format("DD-MM-YYYY"),
                status:status,
                is_campaign_running:item.is_campaign_running
              };
              
          });
          setCampaigns(campaign_temp);
        }
    } catch (error) {
      
    }
  }
  useEffect(()=>{
    getCampaigns();
  },[reload])
  return (
   <>
   <div className='pt-4 ps-5 pe-5'>
    <div>
      <div className='heading'>Campaign Management</div>
    </div>
    <div className='mt-4'>
      <div>
        <div className="d-flex  justify-content-end">
        <CustomAddButton buttonText="New Campaign" onClick={gotoEnablerAd}/>
        </div>
       
      </div>
    </div>
    <div className='mt-4'>
      <div>
        <CustomTable dataSource={campaigns} columns={columns}/>
      </div>
    </div>
   </div>
   </>
  )
}

export default CampaignManagement