import { Select } from 'antd';
import React from 'react';
import './CustomSelect.scss'

const CustomSelect = ({data,onChange}) => {

    const {Option}  = Select;
    return (
    <Select
        className='custom-select'
        onChange={onChange}
      placeholder="Select options"
     
    >
      {data?.map((item) => (
        <Option key={item?.value} value={item?.value}>
          {item.label}
        </Option>
      ))}
    </Select>
    );
}

export default CustomSelect;
