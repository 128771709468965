/* 
function will return a status indicating whether current date is between a date range or
before a date range or after a date range

status 0:Before the date range
status 1:Between th date range
status 2:After the date range

*/

export const getCurrentDateStatus=(startDate,endDate)=>{
    let startDateObj=new Date(startDate);
    let endDateObj=new Date(endDate);
    let currentDate=new Date();
    if(endDateObj<currentDate){
        console.log("campagin over");
        return 2;
    } 
    if(currentDate<startDateObj){
        console.log("campain in pipeline")
        return 0;
    }
    if(currentDate>startDateObj && currentDate<endDateObj){
        console.log("campagin active");
        return 1;
    }
}