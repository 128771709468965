import React, { useEffect, useState } from 'react'
import CustomAddButton from '../../../components/Admin/CustomAddButton'
import { SearchOutlined,EditOutlined,DeleteOutlined } from "@ant-design/icons";
import { Input, Space, message } from 'antd';
import CustomTable from '../../../components/Admin/CustomTable';
import { useNavigate } from 'react-router-dom';
import './SubscriptionManagement.scss'
import AxiosInstance from '../../../utils/AxiosInstance';
import { DELETE_SUBSCRIPTION_MANAGMENT_API, LIST_CAMPAIGNS_API, LIST_SUBSCRIPTION_API } from '../../../Api/Api';
const SubscriptionManagement = () => {
  const navigate=useNavigate();
  const [data,setData] = useState([])

  const handleEdit = (id)=>{
    
      navigate(`/admin/edit-plan/${id}`)
  }

  const handleDelete = (id)=>{
        AxiosInstance.post(DELETE_SUBSCRIPTION_MANAGMENT_API,{id:parseInt(id)}).then((res)=>{
          if(res?.status ===200){
            getAlldata()
          }else{
            message.error('Something went wrong')
          }
        }).catch((err)=>{
          message.error(err)
        
        })
  }

  const getAlldata = ()=>{
      AxiosInstance.post(LIST_SUBSCRIPTION_API).then((res)=>{
        
        if(res.status === 200){
          
          
          setData(res?.data)
        }
        else{
          throw new Error('something went wrong')
        }
      }).catch((Err)=>{
        console.log(Err);
      })
  }

  useEffect(()=>{
      getAlldata()
  },[])

  

  const columns = data?.map(item => ({
    title: (
      <Space>
        <div>{item.plan_name}</div>
        <EditOutlined onClick={() => handleEdit(item.id)} style={{ cursor: 'pointer' }} />
        <DeleteOutlined onClick={() => handleDelete(item.id)} style={{ cursor: 'pointer' }} />
      </Space>
    ),
    dataIndex: item.plan_name,
    key: item.plan_name,
    render: (text, record) => {
      return (
        <div>
          {record[item.plan_name]}
        </div>  
      );
    },
  }));

  // Extracting the common keys from all data objects
  const commonKeys = Object.keys(data?.reduce((prev, curr) => Object.assign(prev, curr), {}));

  // Generating dataSource
  const dataSource = commonKeys?.map(key => {
    const row = { key };
    data.forEach(item => {
      row[item.plan_name] = item[key];
    });
    return row;
  });

  


  
  
  const gotoNewPlan=()=>{
    navigate("/admin/subscription_management/create-new-plan");
  }
  return (
  <>
  <div className='pt-4 ps-5 pe-5'>
    <div>
      <div className='subscription-management-heading'>Subscription Management</div>
    </div>
    <div className='mt-4'>
      <div className='d-flex justify-content-between'>
        <div>
        <Input
                //placeholder="Search"
                style={{ width: "494px", height: "49px", borderRadius: "6px",border:"none",boxShadow:"0px 3px 6px rgba(0, 0, 0, 0.25)" }}
                prefix={<SearchOutlined style={{ color: "#D4D4D4" }} />}
              />
        </div>
        <div>
          <CustomAddButton buttonText="Create New" onClick={()=>{ navigate("/admin/create-new-plan");}}/>
        </div>
      </div>
    </div>
    <div className='mt-4'>
      <div>
        <CustomTable columns={columns} dataSource={dataSource}/>
      </div>
    </div>
  </div>
  </>
  )
}

export default SubscriptionManagement