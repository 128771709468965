import { Table } from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';

const ActiveUsers = () => {

    const columns = [
        
        {
            title: "Username",
            dataIndex: "username",
            key: "username",

        },
        {
            title: "City",
            dataIndex: "city",
            key: "city",

        },
        {
            title: "Phone ",
            dataIndex: "phone",
            key: "phone",

        },
        {
            title: "Current Plan ",
            dataIndex: "plan",
            key: "plan",

        },
        {
            title: "Total Spending ",
            dataIndex: "total",
            key: "total",

        },
        {
            title: "Current Coins ",
            dataIndex: "coins",
            key: "coins",

        },
        {
            title: "Followers ",
            dataIndex: "followers",
            key: "followers",

        },
        {
            title: "Following ",
            dataIndex: "following",
            key: "following",

        },
        
    ]

    const data = [
        {
            username:'test',
            city:'Kochi',
            phone:1234567892,
            plan:'Plan1',
            coins:15,
            total:1300,
            followers:4,
            following:13
        },
        {
            username:'test',
            city:'Kochi',
            phone:1234567892,
            plan:'Plan1',
            coins:15,
            total:1300,
            followers:4,
            following:13
        },
        {
            username:'test',
            city:'Kochi',
            phone:1234567892,
            plan:'Plan1',
            coins:15,
            total:1300,
            followers:4,
            following:13
        },
        {
            username:'test',
            city:'Kochi',
            phone:1234567892,
            plan:'Plan1',
            coins:15,
            total:1300,
            followers:4,
            following:13
        },
    ]
    return (
        <>
            <div style={{paddingBottom:'35px'}}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span>
                        <Title style={{ font: 'lato', fontWeight: '600', fontSize: '30px', lineHeight: '12px' }}>Top Active Users</Title>
                    </span>
                    <span style={{ font: 'lato', fontWeight: '600', fontSize: '20px', lineHeight: '21px', cursor: 'pointer', color: '#C62137', textDecoration: 'underline' }}>View All Users</span>
                </div>
                <Table columns={columns} dataSource={data} pagination={false}/>
            </div>
        </>
    );
}

export default ActiveUsers;
