import React, { useEffect, useState } from "react";
import "./AdminHeader.scss";
import { MdNotifications } from "react-icons/md";
import { BsPersonCircle } from "react-icons/bs";
import { Dropdown, Space, message } from "antd";
import AxiosInstance from "../../utils/AxiosInstance";
import { useLocation, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { LOGOUT_API } from "../../Api/Api";
import { LOGIN_ROUTE } from "../../Routes/Route";

const AdminHeader = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [withScrollBar, setWithScrollBar] = useState(false);

  const handleLogout = async () => {
    try {
      const response = await AxiosInstance.post(LOGOUT_API);
      if (response.status == 200) {
        localStorage.clear("name");
        Cookies.remove("sessionId");
        Cookies.remove("sessionName");
        Cookies.remove("sessionEmail");
        message.success("Logout successful");
        navigate(LOGIN_ROUTE);
      }
    } catch (error) {}
  };
  const items = [
    {
      label: <div onClick={handleLogout}>Logout</div>,
      key: "1",
    },
  ];

  useEffect(() => {
    if (window.innerWidth > document.body.clientWidth) {
      setWithScrollBar(true);
    } else {
      setWithScrollBar(false);
    }
  }, [location]);

  return (
    <>
      {console.log("With Scroll bar status:", withScrollBar)}
      <div
        className={
          withScrollBar
            ? `header_container_with_scroll d-flex align-items-center justify-content-end`
            : "header_container d-flex align-items-center justify-content-end"
        }>
        <div className='d-flex  me-5'>
          <div>
            <div className='me-2'>
              <MdNotifications />
            </div>
          </div>
          <div className='d-flex'>
            <div className='me-2'>
              <BsPersonCircle />
            </div>
            <div>
              <Dropdown menu={{ items }}>
                <div>{localStorage.getItem("name")}</div>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminHeader;
