import { createContext, useState } from "react";
import Cookies from "js-cookie";
const AdminContext = createContext();

function AdminProvider({ children }) {
//   const [userToken, setUserToken] = useState(null);

//   const setUserTokenInfo = (token) => {
//     setUserToken(token);
//     localStorage.setItem("userToken", token);
//   };

  const logoutUser = () => {
    localStorage.clear("name");
    Cookies.remove("sessionId");
    Cookies.remove("sessionName");
    Cookies.remove("sessionEmail");
    window.location.href = "/login";
  };

  const isAuthenticated = () => {
    console.log("inside authentication function");
    //let t = localStorage.getItem("UserToken");
    let t=Cookies.get("sessionId");
    if (!t) {
      console.log("********** Unauthorized");
      return false;
    } else {
      console.log("********** Authorized");
      return true;
    }
  };

  return (
    <AdminContext.Provider
      value={{ logoutUser, isAuthenticated }}
    >
      {children}
    </AdminContext.Provider>
  );
}

export { AdminContext, AdminProvider };