import { Input } from 'antd'
import React from 'react'
import './CustomInput.scss'

const CustomInput = ({placeholder,width,height,onChange,value,defaultValue,autoFocus,backgroundColor}) => {
  const inputWidth=width?width:"100%";
  const inputHeight=height?height:"56px";

  return (
    <><Input   placeholder={placeholder}
    autoFocus={autoFocus?autoFocus:false}
    onChange={onChange}
    value={value}
    defaultValue={defaultValue}
    backgroundColor={backgroundColor}
    style={{
      height: "56px",
      //width: "432.21px",
      width:width,
      fontSize: "15px",
      border: "none",
      borderRadius:"6px",
      backgroundColor:"#F2F2F2"
    }}/></>
  )
}

export default CustomInput