import React, { useEffect, useState } from 'react'
import './AdminSidebar.scss'
import { useNavigate,useLocation } from 'react-router-dom'

const AdminSidebar = () => {
    const navigate=useNavigate();
    const location=useLocation();
    const {pathname}=location;
    const exactPathurl=pathname.split("/")[2];
    const [exactPath,setexactPath]=useState(exactPathurl);
    console.log("exact path=",pathname.split("/")[2]);
    const [pathUrl,setPathUrl]=useState(pathname.split("/")[2]);
    const [showSubMenu,setShowSubMenu]=useState({
        userManagement:false,
        settings:false,
        masterData:false
    });
    
    const [userManagementSubmenu,setUserManagementSubmenu]=useState({
        roles:true,
        user:false
    });
    const [settingSubmenu,setSettingsSubmenu]=useState({
        subscriptionManagement:true,
        accountingValues:false,
        versionControl:false,
        termsAndConditions:false,
        privacyPolicy:false,
        refundPolicy:false
    })
    const gotoDashboard=()=>{
        navigate("/admin")
    }
    const gotoMobileUserActivities=()=>{
        navigate("/admin/moblile_user_activities");
    }
    const gotoEnablerManagement=()=>{
        navigate("/admin/enabler_management");
    }
    const gotoCampaignManagement=()=>{
        navigate("/admin/campaign_management");
    }
    const gotoReports=()=>{
        navigate("/admin/reports");
    }
    const gotoSettings=()=>{
        navigate("/admin/settings");
    }
    const gotoUserManagement=()=>{
        navigate("/admin/user_management_roles  ");
    }
    const gotoUserManagementUser=()=>{
        navigate("/admin/user_management_user");
    }
    const gotoSubscriptionManagement=()=>{
        navigate("/admin/subscription_management");
    }
    const gotoMasterData=()=>{
        navigate("/admin/master_data");
    }
    const gotoEnablerCategoryMaster=()=>{
        navigate("/admin/enabler_category_master");
    }
    const gotoVersionControl=()=>{
        navigate("/admin/version_control");
    }
    const gotoTermsAndConditions=()=>{
        navigate("/admin/terms_and_conditions");
    }
    const gotoPrivacyPolicy=()=>{
        navigate("/admin/privacy_policy");
    }
    const gotoHobbies=()=>{
        navigate("/admin/hobbies");
    }

    const gotoRefundpolicy = ()=>{
        navigate("/admin/refund_policy")
    }
    useEffect(()=>{

    },[pathname])
  return (
    <>
    <div className='sidebar_container pt-5 ps-2 pe-2'>
        <div>
            <div className="d-flex">
                <div>
                    <div className='circle'></div>
                </div>
                <div className='d-flex justify-content-center align-items-center ms-2'>
                    <div className='sidebar_heading'>Friendship App</div>
                </div>
            </div>
        </div>
        <div className='mt-4'>
            <div>
            <div  className={pathname.split("/")[1]=== "admin"&&pathname.split("/")[2]===undefined ?`sidebar_item_active ps-4 mt-1 pt-3 pb-3 pe-2`:`sidebar_item ps-4 mt-1 pt-3 pb-3 pe-2`} onClick={()=>{setShowSubMenu({...showSubMenu,settings:false,masterData:false,userManagement:false});gotoDashboard();}}>
                    <div className="d-flex">
                       <div>
                        <img src="/images/Dashboard.png" alt="" />
                       </div>
                       <div className='ms-2'>  <div className='sidebar_text'>Dashboard</div></div> 
                    </div>
                  
                </div>
                <div className={pathname.split("/")[2] === "user_management_roles"||pathname.split("/")[2] === "user_management_user"||pathname.split("/")[2] === "user_management_create_new_user" ||pathname.split("/")[2] === "user_management_edit_user"?`sidebar_item_active ps-4 mt-1 pt-3 pb-3 pe-2` :`sidebar_item ps-4 mt-1 pt-3 pb-3 pe-2`} onClick={()=>{
                    setShowSubMenu({...showSubMenu,userManagement:true,settings:false,masterData:false});
                    gotoUserManagement();
                }}>
                    <div className="d-flex">
                        <div>
                        <img src="/images/userManagement.png" alt="" />
                        </div>
                        <div className='ms-2'> 
                        <div className='sidebar_text'>User Management</div>
                        </div>
                       
                    </div>
           
                 
                  
                </div>
                {
                        showSubMenu.userManagement && <>
                               <div className='submenu-container pt-3 pb-3'>
                        <div className='ms-5'>
                            <div className={pathname.split("/")[2] === "user_management_roles"?`submenu-item-active`:`submenu-item`} onClick={()=>{gotoUserManagement();setUserManagementSubmenu({...userManagementSubmenu,roles:true,user:false})}}>Roles</div>
                        </div>
                        <div className='ms-5 mt-4'>
                            <div   className={pathname.split("/")[2] === "user_management_user"||pathname.split("/")[2] === "user_management_create_new_user" ||pathname.split("/")[2] === "user_management_edit_user"?`submenu-item-active`:`submenu-item`} onClick={()=>{gotoUserManagementUser();setUserManagementSubmenu({...userManagementSubmenu,roles:false,user:true})}}>Users</div>
                        </div>
                    </div>
                        </>
                    }
                <div className={pathname.split("/")[2] === "reports"?`sidebar_item_active ps-4 mt-1 pt-3 pb-3 pe-2`:`sidebar_item ps-4 mt-1 pt-3 pb-3 pe-2`} onClick={()=>{setShowSubMenu({...showSubMenu,settings:false,masterData:false,userManagement:false});gotoReports();}}>
                    <div className="d-flex">
                        <div>
                        <img src="/images/Reports.png" alt="" />
                        </div>
                        <div className='ms-2'>
                        <div className='sidebar_text'>Reports</div>
                        </div>
                    </div>
                 
                </div>
                <div className={pathname.split("/")[2] === "enabler_management"?`sidebar_item_active ps-4 mt-1 pt-3 pb-3 pe-2`:`sidebar_item ps-4 mt-1 pt-3 pb-3 pe-2`} onClick={()=>{setShowSubMenu({...showSubMenu,settings:false,masterData:false,userManagement:false});gotoEnablerManagement();}}>
                    <div className="d-flex">
                        <div>
                        <img src="/images/enablerManagement.png" alt="" />
                        </div>
                        <div className='ms-2'>
                        <div className='sidebar_text'>Manage Enablers</div>
                        </div>
                    </div>
                  
                </div>
                <div className={pathname.split("/")[2] === "campaign_management"||pathname.split("/")[2] === "enabler_ad"||pathname.split("/")[2] === "new_users"||pathname.split("/")[2] === "existing_users"?`sidebar_item_active ps-4 mt-1 pt-3 pb-3 pe-2`:`sidebar_item ps-4 mt-1 pt-3 pb-3 pe-2`} onClick={()=>{setShowSubMenu({...showSubMenu,settings:false,masterData:false,userManagement:false});gotoCampaignManagement();}}>
                    <div className="d-flex">
                    <div>
                    <img src="/images/campaignManagement.png" alt="" />
                    </div>
                    <div className='ms-2'>
                    <div className='sidebar_text'>Campaign Management</div>
                    </div>
                    </div>
              
                   
                </div>
                <div className='sidebar_item ps-4 mt-1 pt-3 pb-3 pe-2' onClick={()=>{setShowSubMenu({...showSubMenu,settings:false,masterData:false,userManagement:false})}}>
                    <div className="d-flex">
                        <div>
                        <img src="/images/support.png" alt="" />
                        </div>
                        <div className='ms-2'>
                        <div className='sidebar_text'>
                      Support
                    </div>
                        </div>
                    </div>
                   
                </div>
                
                <div className={pathname.split("/")[2] === "settings"||pathname.split("/")[2] === "subscription_management"||pathname.split("/")[2] === "version_control"||pathname.split("/")[2] === "terms_and_conditions"||pathname.split("/")[2] === "privacy_policy" ||pathname.split("/")[2] === "refund_policy" || pathname.split("/")[2]==="create-new-plan"?`sidebar_item_active ps-4 mt-1 pt-3 pb-3 pe-2`:`sidebar_item ps-4 mt-1 pt-3 pb-3 pe-2`}  onClick={()=>{setShowSubMenu({settings:true,userManagement:false,masterData:false});gotoSettings();}}>
                    <div className="d-flex">
                        <div>
                        <img src="/images/settings.png" alt="" />
                        </div>
                        <div className='ms-2'>
                        <div className='sidebar_text'>
                      Settings
                    </div>
                        </div>
                    </div>
              
                </div>
                {
                        showSubMenu.settings && <>
                               <div className='submenu-container pt-3 pb-3'>
                        <div className='ms-5'>
                            <div   className={pathname.split("/")[2] === "subscription_management" ||pathname.split("/")[2]==="create-new-plan"?`submenu-item-active`:`submenu-item`} onClick={gotoSubscriptionManagement}>Subscription Management</div>
                        </div>
                        <div className='ms-5 mt-4'>
                            <div  className={pathname.split("/")[2] === "accounting_values"?`submenu-item-active`:`submenu-item`}>Accounting values</div>
                        </div>
                        <div className='ms-5 mt-4'>
                            <div  className={pathname.split("/")[2] === "version_control"?`submenu-item-active`:`submenu-item`} onClick={gotoVersionControl}>Version control</div>
                        </div>
                        <div className='ms-5 mt-4'>
                            <div  className={pathname.split("/")[2] === "terms_and_conditions"?`submenu-item-active`:`submenu-item`} onClick={gotoTermsAndConditions}>Terms & Conditions</div>
                        </div>
                        <div className='ms-5 mt-4'>
                            <div  className={pathname.split("/")[2] === "privacy_policy"?`submenu-item-active`:`submenu-item`} onClick={gotoPrivacyPolicy}>Privacy Policy</div>
                        </div>
                        <div className='ms-5 mt-4'>
                            <div  className={pathname.split("/")[2] === "refund_policy"?`submenu-item-active`:`submenu-item`} onClick={gotoRefundpolicy}>Refund Policy</div>
                        </div>
                    </div>
                        </>
                    }
            
                <div className={pathname.split("/")[2] === "master_data"||pathname.split("/")[2] === "hobbies"?`sidebar_item_active ps-4 mt-1 pt-3 pb-3 pe-2`:`sidebar_item ps-4 mt-1 pt-3 pb-3 pe-2`}  onClick={()=>{
                    setShowSubMenu({showSubMenu,settings:false,masterData:true,userManagement:false});
                    // gotoMasterData();
                    gotoHobbies()
                }}>
                    <div className="d-flex">
                        <div>
                        <img src="/images/masterData.png" alt="" />
                        </div>
                        <div className='ms-2'>
                        <div className='sidebar_text'>Master Data</div>
                        </div>
                    </div>
                   
                </div>
                {
                        showSubMenu.masterData && <>
                               <div className='submenu-container pt-3 pb-3'>
                        <div className='ms-5'>
                            <div className={pathname.split("/")[2] === "hobbies"?`submenu-item-active`:`submenu-item`} onClick={gotoHobbies}>Hobbies</div>
                        </div>
                        <div className='ms-5 mt-4'>
                            <div className={pathname.split("/")[2] === "accounting_values"?`submenu-item-active`:`submenu-item`}>Subscription Features</div>
                        </div>
                        <div className='ms-5 mt-4'>
                            <div  className={pathname.split("/")[2] === "accounting_values"?`submenu-item-active`:`submenu-item`}>Enabler Category Master</div>
                        </div>
                    </div>
                        </>
                    }
                {/* <div className='sidebar_item ps-4 mt-1 pt-3 pb-3 pe-2' onClick={gotoMasterData}> 
                    <div className='sidebar_text'>Master data</div>
                </div>
                <div className='sidebar_item ps-4 mt-1 pt-3 pb-3 pe-2' onClick={gotoEnablerCategoryMaster}>
                    <div className='sidebar_text'>Enabler Category Master</div>
                </div>
                <div className='sidebar_item ps-4 mt-1 pt-3 pb-3 pe-2' onClick={gotoVersionControl}>
                    <div className='sidebar_text'>Version Control</div>
                </div>
                <div className='sidebar_item ps-4 mt-1 pt-3 pb-3 pe-2' onClick={gotoTermsAndConditions}>
                    <div className='sidebar_text'>Terms & Conditions</div>
                </div>
                <div className='sidebar_item ps-4 mt-1 pt-3 pb-3 pe-2' onClick={gotoPrivacyPolicy}>
                    <div className='sidebar_text'>Privacy & Policy</div>
                </div> */}
                <div></div>
            </div>
        </div>
    </div>
    </>
  )
}

export default AdminSidebar