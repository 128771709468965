import { Button } from 'antd'
import React from 'react'
import './CustomButtonGroup.scss'
import { useLocation, useNavigate } from 'react-router-dom'

const CustomButtonGroup = () => {
  const location=useLocation();
  const navigate=useNavigate();
  console.log("location",location.pathname.split("/")[location.pathname.split("/").length-1]);
  return (
    <>
    <div>
        <div className="d-flex btn-group-container">
            <Button className={`${location.pathname.split("/")[location.pathname.split("/").length-1]=="enabler_ad"?'custom_btn_active':'custom_btn'}`} onClick={()=>{navigate("/admin/enabler_ad")}}>Enabler Ad</Button>
            <Button  className={`${location.pathname.split("/")[location.pathname.split("/").length-1]=="new_users"?'custom_btn_active':'custom_btn'}`}onClick={()=>{navigate("/admin/new_users")}}>New users</Button>
            <Button className={`${location.pathname.split("/")[location.pathname.split("/").length-1]=="existing_users"?'custom_btn_active':'custom_btn'}`}onClick={()=>{navigate("/admin/existing_users")}}>Subscription Plan</Button>
        </div>
    </div>
    </>
  )
}

export default CustomButtonGroup