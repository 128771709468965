import { Input } from 'antd'
import React from 'react'

const CustomPassword = ({placeholder,onChange}) => {
  return (
    <>
    <div className='password_wrapper'>
    <Input.Password placeholder={placeholder} onChange={onChange}  style={{
      height: "56px",
      //width: "432.21px",
     width:"100%",
      fontSize: "15px",
      border: "none",
      borderRadius:"6px",
      backgroundColor:"#F2F2F2"
    }}/>
    </div>
   
    </>
  )
}

export default CustomPassword