import { Card, Select } from 'antd';
import { Option } from 'antd/es/mentions';
import React, { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend
} from "recharts";

const data = [
  {
    name: "Week 1",
    uv: 4000,
    pv: 2400,
    amt: 2400
  },
  {
    name: "Week 2",
    uv: 3000,
    pv: 1398,
    amt: 2210
  },
  {
    name: "Week 3",
    uv: 2000,
    pv: 9800,
    amt: 2290
  },
  {
    name: "Week 4",
    uv: 12780,
    pv: 13908,
    amt: 2000
  },
  {
    name: "Week 5",
    uv: 1890,
    pv: 4800,
    amt: 2181
  }
];


const UsersGraph = () => {

  const [selectedRange, setSelectedRange] = useState("lastMonth");

  const handleRangeChange = (value) => {
    setSelectedRange(value);
  };

  // Filter the data based on the selected range
  const filteredData = () => {
    switch (selectedRange) {
      case "lastMonth":
        return data.slice(-4); // Show last 4 weeks (assuming 1 week per data point)
      case "last3Months":
        return data.slice(-12); // Show last 12 weeks (assuming 1 week per data point)
      case "last6Months":
        return data.slice(-24); // Show last 24 weeks (assuming 1 week per data point)
      case "lastYear":
        return data; // Show all data (1 year)
      default:
        return data;
    }
  };

  const formatXAxis = (tickItem) => `Week ${tickItem}`;
 
  return (
    <div className="graph-card">
      <h2>Total User Graph</h2>
      <div className="graph-container">
      <Card
    style={{
      width: 870,
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'
    }}
  >
      <span
            style={{ cursor: "pointer",marginLeft:'90px', marginRight: "20px" }}
            onClick={() => handleRangeChange("lastMonth")}
          >
            Last Month
          </span>
          <span
            style={{ cursor: "pointer", marginRight: "10px" }}
            onClick={() => handleRangeChange("last3Months")}
          >
            Last 3 Months
          </span>
          <span
            style={{ cursor: "pointer", marginRight: "10px" }}
            onClick={() => handleRangeChange("last6Months")}
          >
            Last 6 Months
          </span>
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleRangeChange("lastYear")}
          >
            Last Year
          </span>
     <LineChart
      width={800}
      height={300}
      data={data}
      margin={{
        top: 5,
        right: 30,
        left: 20,
        bottom: 5
      }}
    >
      <CartesianGrid vertical={false} />
      <XAxis dataKey={"name"} />
      <YAxis />
      <Tooltip />
      <Line
        type="monotone"
        dataKey="pv"
        stroke="#c62137"
        activeDot={{ r: 8 }}
      />
    </LineChart>
  </Card>
      </div>
    </div>
  );
};

export default UsersGraph;
