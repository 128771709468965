import React, { useEffect, useState } from 'react'
import CustomAddButton from '../../../components/Admin/CustomAddButton'
import { SearchOutlined } from "@ant-design/icons";
import { Input,Checkbox, Form, message } from 'antd';
import CustomTable from '../../../components/Admin/CustomTable';
import { useNavigate } from 'react-router-dom';
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import CustomModal from '../../../components/Admin/CustomModal';
import CustomInput from '../../../components/Admin/CustomInput';
import CustomButton from '../../../components/Admin/CustomButton';
import AxiosInstance from '../../../utils/AxiosInstance';
import { ADD_HOBBY_API, DELETE_HOBBY_API, GET_HOBBIES_API, UPDATE_HOBBY_API } from '../../../Api/Api';
import { useForm } from 'antd/es/form/Form';

const Hobbies = () => {
  const navigate=useNavigate();
  const [hobbies,setHobbies]=useState([]);
  const [hobbyName,setHobbyName]=useState();
  const [showAddHobbiesModal,setShowAddHobbiesModal]=useState(false);
  const [showEditHobbiesModal,setShowEditHobbiesModal]=useState(false);
  const [reload,setReload]=useState(false);
  const [addHobbyForm]=Form.useForm();
  const [editHobbyForm]=Form.useForm();
  const columns=[
    // {
    //   dataIndex: "action",
    //   render: (record) => (
    //     <>
    //       <div className="d-flex justify-content-center" >
    //        <Checkbox checked/>
            
    //       </div>
    //     </>
    //   ),
    // },
    {
      title: "Hobbies",
      dataIndex: "hobbyName",
      key: "hobbyName",
    },
    {
      dataIndex: "action",
      render: (data,record) => (
        <>
          <div className="d-flex justify-content-center" >
            <BiEditAlt
              className="icons"
             // onClick=""
             onClick={()=>{
              setShowEditHobbiesModal(true);
             }}
            />
            <RiDeleteBinLine className="icons ms-3 " onClick={()=>{deleteHobby(record)}}/>
          </div>
        </>
      ),
    },
   
  ];
  const deleteHobby=async(record)=>{
    try {
      const response=await AxiosInstance.post(DELETE_HOBBY_API,{
        hobbyId:record.hobbyId
      });
      if(response.status==200){
        message.success("Hobby Deleted ");
        setReload(!reload);
      }
    } catch (error) {
      
    }
  }
  
  const getHobbies=async()=>{
    try {
      const response=await AxiosInstance.post(GET_HOBBIES_API,{
        search:""
      });
      console.log("here is hobbies response",response);
      if(response.status==200){
        let hobbies_temp=response?.data?.map((item)=>{
          return{
            hobbyName:item?.hobby_name,
            hobbyId:item?.id
          }
        })
        setHobbies(hobbies_temp)
      }
    } catch (error) {
      
    }
  }
  
  const addHobby=async()=>{
    try {
      const response=await AxiosInstance.post(ADD_HOBBY_API,{
        hobbyName
      });
      if(response.status===200){
        setReload(!reload)
        message.success("Hobby Added successfully");
        setShowAddHobbiesModal(false);
        addHobbyForm.resetFields();
       
      }
    } catch (error) {
      if(error.response.data.message === 'Already exist'){
        message.error(`Hobbie alreday exist`)
    }
    }
  }
  const editHobby=async()=>{
    try {
      const response=await AxiosInstance.post(UPDATE_HOBBY_API,{
        hobbyName:editHobbyForm.getFieldValue().hobbyName,
        hobbyId:editHobbyForm.getFieldValue().hobbyId
      });
      if(response.status===200){
        setShowEditHobbiesModal(false);
        editHobbyForm.resetFields();
        message.success("Hobby Edited");
        setReload(!reload);
        
      }
    } catch (error) {
      if(error.response.data.message === 'Already exist'){
        message.error(`Hobbie alreday exist`)
    }
    }
  }

  const handleRowClick = (record) => {
    console.log("handle row click",record);
    editHobbyForm.setFieldsValue({
      hobbyName:record.hobbyName,
      hobbyId:record.hobbyId
    })
  };
  const rowProps = (record, index) => {
    return {
      onClick: () => handleRowClick(record),
    };
  };

  const filterHobbies=async(e)=>{
    if(e.target.value.split("")[0]===" "){
      return;
    }
    try {
      const response=await AxiosInstance.post(GET_HOBBIES_API,{
        search:e.target.value
      });
      if(response.status==200){
        let hobbies_temp=response?.data?.map((item)=>{
          return{
            hobbyName:item?.hobby_name,
            hobbyId:item?.id
          }
        })
        setHobbies(hobbies_temp)
      }
    } catch (error) {
      
    }
  }

  useEffect(()=>{
    console.log("inside use effect")
    getHobbies();
  },[reload])

  return (
    <>
    <div className='pt-4 ps-5 pe-5'>
    <div>
      <div className='subscription-management-heading'>Hobbies</div>
    </div>
    <div className='mt-4'>
      <div className='d-flex justify-content-between'>
        <div>
        <Input
                //placeholder="Search"
                style={{ width: "494px", height: "49px", borderRadius: "6px",border:"none",boxShadow:"0px 3px 6px rgba(0, 0, 0, 0.25)" }}
                prefix={<SearchOutlined style={{ color: "#D4D4D4" }} />}
                onChange={filterHobbies}
              />
        </div>
        <div>
          <CustomAddButton buttonText="Create New" onClick={()=>{setShowAddHobbiesModal(true)}}/>
        </div>
      </div>
    </div>
    <div className='mt-4'>
      <div>
        <CustomTable columns={columns} dataSource={hobbies} onRow={rowProps}/>
      </div>
    </div>
    <CustomModal title="Add Hobbies" open={showAddHobbiesModal} onCancel={()=>{setShowAddHobbiesModal(false);addHobbyForm.resetFields();}}>
      <Form  onFinish={addHobby} form={addHobbyForm}>
        {/* <div>
          <div className='form-label'>Hobby Id</div>
          <div>
            <Form.Item>
              <CustomInput />
            </Form.Item>
          </div>
        </div>   */}
        <div>
        <div className='form-label'>Hobby Name</div>
          <div>
            <Form.Item name="hobbyName" rules={[{required:true,message:"Please Enter Hobby Name"},{pattern:/^(?!^\s+$)[a-zA-Z\s]+$/,message:"invalid hobby name"},{max:50,message:'Maximum allowed character is 50'}]}>
             <CustomInput onChange={(e)=>{setHobbyName(e.target.value)}}/>
            </Form.Item>
          </div>
        </div>
        <div className='mt-3'>
          <CustomButton type="save" buttonText="Save" htmlType="submit"/>
        </div>
      </Form>
    </CustomModal>
    <CustomModal title="Edit Hobbies" open={showEditHobbiesModal} onCancel={()=>{setShowEditHobbiesModal(false);editHobbyForm.resetFields();}}>
      <Form  onFinish={editHobby} form={editHobbyForm}>
        {/* <div>
          <div className='form-label'>Hobby Id</div>
          <div>
            <Form.Item>
              <CustomInput />
            </Form.Item>
          </div>
        </div>   */}
        <div>
        <div className='form-label'>Hobby Name</div>
          <div>
            <Form.Item name="hobbyName" rules={[{required:true,message:"Please Enter Hobby Name"},{pattern:/^(?!^\s+$)[a-zA-Z\s]+$/,message:"invalid hobby name"},{max:50,message:'Maximum allowed character is 50'}]}>
             <CustomInput onChange={(e)=>{setHobbyName(e.target.value)}}/>
            </Form.Item>
            <Form.Item name="hobbyId" hidden={true}>
            <input type='hidden'/>
          </Form.Item>
          </div>
        </div>
        <div className='mt-3'>
          <CustomButton type="save" buttonText="Save" htmlType="submit"/>
        </div>
      </Form>
    </CustomModal>
  </div>
    </>
  )
}

export default Hobbies