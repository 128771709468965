import { Card } from 'antd';
import Title from 'antd/es/typography/Title';

import React from 'react';
import { ResponsivePie } from '@nivo/pie';

const SubplanPiechart = () => {

  const data = [
    {
      id: 'Plan1',
      label: 'Plan1 users',
      value: 558,
    },
    {
      id: 'Plan2',
      label: 'Plan2 users',
      value: 463,
    },
    {
      id: 'Plan3',
      label: 'Plan3 users',
      value: 267,
    },
  ];

  // Custom color mapping function
  const getColor = (bar) => {
    switch (bar.id) {
      case 'Plan1':
        return 'hsla(281, 100%, 85%, 1)';
      case 'Plan2':
        return 'hsla(220, 100%, 86%, 1)';
      case 'Plan3':
        return 'hsla(0, 100%, 86%, 1)';
      default:
        return '#000'; // Fallback color if needed
    }
  };
    
    return (
        <>
        <div>
        <Card style={{width:'424.61px',height:'420px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'}}>
        <Title style={{ fontFamily: 'lato', lineHeight: '12px', fontSize: '20px', fontWeight: '600' }}>Subscription Plan</Title>
        <div  style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height:"350px" }}>
        <ResponsivePie
        data={data}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        startAngle={-65}
        innerRadius={0.8}
        padAngle={4}
        activeOuterRadiusOffset={8}
        colors={getColor}
        borderWidth={1}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    0.2
                ]
            ]
        }}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsOffset={-1}
        arcLinkLabelsDiagonalLength={18}
        arcLinkLabelsColor={{ from: 'color' }}
        enableArcLabels={false}
        arcLabelsRadiusOffset={0.35}
        arcLabelsTextColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    2
                ]
            ]
        }}
        defs={[
            // {
            //     id: 'dots',
            //     type: 'patternDots',
            //     background: 'inherit',
            //     color: 'rgba(255, 255, 255, 0.3)',
            //     size: 4,
            //     padding: 1,
            //     stagger: true
            // },
            // {
            //     id: 'lines',
            //     type: 'patternLines',
            //     background: 'inherit',
            //     color: 'rgba(255, 255, 255, 0.3)',
            //     rotation: -45,
            //     lineWidth: 6,
            //     spacing: 10
            // }
        ]}
        fill={[
            // {
            //     match: {
            //         id: 'ruby'
            //     },
            //     id: 'dots'
            // },
            // {
            //     match: {
            //         id: 'c'
            //     },
            //     id: 'dots'
            // },
            // {
            //     match: {
            //         id: 'go'
            //     },
            //     id: 'dots'
            // },
            // {
            //     match: {
            //         id: 'python'
            //     },
            //     id: 'dots'
            // },
            // {
            //     match: {
            //         id: 'scala'
            //     },
            //     id: 'lines'
            // },
            // {
            //     match: {
            //         id: 'lisp'
            //     },
            //     id: 'lines'
            // },
            // {
            //     match: {
            //         id: 'elixir'
            //     },
            //     id: 'lines'
            // },
            // {
            //     match: {
            //         id: 'javascript'
            //     },
            //     id: 'lines'
            // }
        ]}
        legends={[]}
    />  

        </div>
            
        </Card>
       </div>
        </>
    );
}

export default SubplanPiechart;
