import React, { useEffect, useState } from 'react'
import CustomAddButton from '../../../components/Admin/CustomAddButton'
import { Form,Input, message } from 'antd'
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import CustomTable from '../../../components/Admin/CustomTable';
import './UserManagement.scss'
import CustomModal from '../../../components/Admin/CustomModal';
import CustomInput from '../../../components/Admin/CustomInput';
import CustomButton from '../../../components/Admin/CustomButton';
import AxiosInstance from '../../../utils/AxiosInstance';
import moment from 'moment';
import { ADD_ROLES_API, DELETE_ROLES_API, EDIT_ROLES_API, GET_ROLES_API } from '../../../Api/Api';
const UserManagement = () => {
  const [addRolesModal,setAddRolesModal]=useState(false);
  const [editRolesModal,setEditRolesModal]=useState(false);
  const [roletoEdit,setRoleToEdit]=useState();
  const [addRoleData,setAddRoleData]=useState({
    roleName:"",
    description:""
  });
  const [editRoleData,setEditRoleData]=useState({
    roleName:"",
    description:"",
    id:""
  })
  const [roles,setRoles]=useState([]);
  const [reload,setReload]=useState(false);
  const [addRoleForm]=Form.useForm();
  const [editRoleForm]=Form.useForm();
  const [data,setData] = useState()
  const [roleCheck, setroleCheck] = useState(false)
  const handleEditClick=(record,e)=>{
    console.log(e)
    console.log("inside handle edit click")
    console.log("record=",record);
    addRoleForm.setFieldValue({
      
    })
  }

  ///validation for editing
  const [edit,setEdit] = useState(false);
  const columns = [
    {
      title: "Role Name",
      dataIndex: "roleName",
      key: "roleName",
    
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      className: "centered_cell",
    },
    {
      title: "Created Date",
      dataIndex: "createdDate",
      key: "createdDate",
      //className: "centered_cell",
    },
    {
      dataIndex: "action",
      render: (data,record) => (
        <>
 
          <div className="d-flex justify-content-center" >
            <BiEditAlt
              className="icons"
              onClick={(e)=>{
               // handleEditClick(record,e);
                setEditRolesModal(true)}}
            />
            <RiDeleteBinLine className="icons ms-3 " onClick={()=>{handleDeleteRole(record)}} />
          </div>
        </>
      ),
    },
  ];

  const handleDeleteRole=async(record)=>{
   
    try {
      const response=await AxiosInstance.post(DELETE_ROLES_API,{
        roleId:record.id
      });
      if(response.status==200){
        message.success("Role deleted successfully");
        setReload(!reload);
      }
    } catch (error) {
      
    }
  }
  const handleRowClick = (record) => {
    editRoleForm.setFieldsValue({
      name:record.roleName,
      description:record.description,
      id:record.id
    })
    setData({name:record.roleName,
      description:record.description,})
  };

  const rowProps = (record, index) => {
    return {
      onClick: () => handleRowClick(record),
    };
  };
  const getAllRoles=async()=>{
    try {
      const response=await AxiosInstance.get(GET_ROLES_API);
      console.log("here is the resonse",response);
      if(response.status==200){
        let rolesData=response.data.map((item,index)=>{
          return {
            roleName:item.role_name,
            description:item.description,
            createdDate:moment( new Date(item.created_at)).format("DD-MM-YYYY"),
            key:index,
            id:item.id
          }
        });
        console.log("here is roles data",rolesData);
        setRoles(rolesData)
      }
    } catch (error) {
      console.log("some error occured",error);
    }
   

  }
  const addRoles=async()=>{
    try {
      const response=await AxiosInstance.post(ADD_ROLES_API,{
        roleName:addRoleData.roleName,
        description:addRoleData.description
      });
      console.log("here is the response after adding roles",response);
      if(response.status==200){
        message.success("role added");
        setAddRolesModal(false);
        addRoleForm.resetFields();
        setReload(!reload);
      }else{
        console.log("error in adding role");
      }
    } catch (error) {
      console.log("error occured",error.response.data.message);
      message.error(error.response.data.message)
       // message.error()
    }
  }
  const editRoles=async()=>{
    console.log("inside edit roles");
    console.log(editRoleForm.getFieldValue());
    let role = editRoleForm.getFieldValue().name
    let desc = editRoleForm.getFieldValue().desc
    try {

      if(role === data.name || desc ===data.description ){
        
        setEdit(true)
        setEditRolesModal(false)
      }else{
          const response=await AxiosInstance.post(EDIT_ROLES_API,
      {
        roleId:editRoleForm.getFieldValue().id,
        roleName:editRoleForm.getFieldValue().name,
        description:editRoleForm.getFieldValue().description
      }
      );
      if(response.status==200){
        message.success("role edited");
        editRoleForm.resetFields();
        setEditRolesModal(false);
        setReload(!reload);
      }
      }
      
    
    } catch (error) {
      
    }
  }
  useEffect(()=>{
    getAllRoles();
  },[reload])

  const handleEdit =(e)=>{
    setEditRoleData({...editRoleData,roleName:e.target.value})
    const name = roles.some((obj)=> obj.name === editRoleData.roleName)
    
    // if(name){
    //   setroleCheck(true)
    // }else{
    //   setroleCheck(false)
    // }
    setEdit(false)
  } 

  return (
  <>
  <div className='pt-4 ps-5 pe-5'>
    <div>
      <div className='heading'>Roles & User Management</div>
    </div>
    <div className='mt-4'>
      <div className='d-flex justify-content-end'>
        <CustomAddButton buttonText="Add Roles" onClick={()=>{setAddRolesModal(true)}}/>
      </div>
    </div>


   
    <div className='mt-4'>
      <div className=''>
        <CustomTable columns={columns} dataSource={roles}   onRow={rowProps}/>
      </div>
      <CustomModal title="Add Roles" open={addRolesModal} onCancel={()=>{setAddRolesModal(false);addRoleForm.resetFields();}}>
        <Form onFinish={addRoles} form={addRoleForm}>
        <div className='form-labels mb-2'>Name</div>
          <Form.Item name="name"  rules={[{ required: true, message: "Please enter a name" },{pattern:/^(?!^\s+$)[a-zA-Z\s]+$/,message:"invalid name"},{max:50,message:"Character limit is 50"}]}>
           
            <CustomInput autoFocus={true} placeholder="Name" onChange={(e)=>{setAddRoleData({...addRoleData,roleName:e.target.value})}}/>
          </Form.Item>
          <div className='form-labels mb-2'>Description</div>
          <Form.Item name="description"     rules={[{ required: true, message: "Enter Description" },{pattern:/^(?!^\s+$)[a-zA-Z\s]+$/,message:"invalid description"},{max:250,message:"Character limit is 250"}]}>
         
            <CustomInput placeholder="Description" onChange={(e)=>{setAddRoleData({...addRoleData,description:e.target.value})}}/>
          </Form.Item>
          <div className='mt-2'>
            <CustomButton type="save" buttonText="Save" htmlType="submit"/>
          </div>
        </Form>
     
      </CustomModal>
      <CustomModal title="Edit Roles" open={editRolesModal} onCancel={()=>{setEditRolesModal(false);editRoleForm.resetFields();}}>
      <Form onFinish={editRoles} form={editRoleForm}>
        <div  className='form-labels mb-2'>Name</div>
          <Form.Item name="name"rules={[{ required: true, message: "Please enter a name" },{pattern:/^(?!^\s+$)[a-zA-Z\s]+$/,message:"invalid name"},{max:50,message:"Character limit is 50"}]}>
            <CustomInput placeholder="Name"  onChange={(e)=>{handleEdit(e)}}/>    
           
          </Form.Item>
          {/* {roleCheck?(<span style={{color:'#ff4d4f'}}>Role is already added</span>):('')}  */}
          <div  className='form-labels mb-2'>Description</div>
          <Form.Item name="description" rules={[{ required: true, message: "Enter Description" },{pattern:/^(?!^\s+$)[a-zA-Z\s]+$/,message:"invalid description"},{max:250,message:"Character limit is 250"}]}>
           
            <CustomInput placeholder="Description"   onChange={(e)=>{setEditRoleData({...editRoleData,description:e.target.value})}}/>
          </Form.Item>
          <Form.Item name="id" hidden={true}>
            <input type='hidden'/>
          </Form.Item>
          <div className='mt-2'>
            <CustomButton type="save" buttonText="Save" htmlType="submit"/>
          </div>
        </Form>
 
      </CustomModal>
    </div>
  </div>
  </>
  )
}

export default UserManagement