import React, { useEffect, useState } from 'react'
import CustomAddButton from '../../../components/Admin/CustomAddButton'
import CustomTable from '../../../components/Admin/CustomTable'
import { useNavigate } from 'react-router-dom'
import AxiosInstance from '../../../utils/AxiosInstance'
import { GET_VERSION_API } from '../../../Api/Api'
import { message } from 'antd'

const VersionControl = () => {

  const navigate = useNavigate();
  const [data,setData] = useState([])
  
  const getData = ()=>{
    AxiosInstance.get(GET_VERSION_API).then((res)=>{
      if(res?.status ===200){
        setData(res?.data)
      }else{
        throw new Error('Something went wrong')
      }
    }).catch((err)=>{
        message.error('Something went wrong');
        console.log(err);
    })
  }

  useEffect(()=>{
    getData()
  },[])

  const columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (data,index) => {
        const dateObj = new Date(data);
        return dateObj.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit'
        }).replace(/\//g, '-');
      },
      width:'150px'
    
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Android Version",
      dataIndex: "android_version",
      key: "android_version",
     
    },
    {
      title: "IOS Version",
      dataIndex: "ios_version",
      key: "ios_version",
     
    },
    {
      title: "Mandatory/Optional",
      dataIndex: "mandatory",
      key: "mandatory",
      render:(data,index)=>{
       
        return(
          <>
          
          {data === 1 ? (
        <div>
          <span>Mandatory</span>
        </div>
      ) : (
        <div>
          <span>Optional</span>
        </div>
      )}
          </>
        )
      }
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Minimum Android Version",
      dataIndex: "minimum_andriod_version",
      key: "minimum_andriod_version",
    },
    {
      title: "Minimum IOS Version",
      dataIndex: "minimum_ios_version",
      key: "minimum_ios_version",
    },
    {
      title: "Supported Android Version",
      dataIndex: "supported_android_version",
      key: "supported_android_version",
    },
    {
      title: "Supported Ios Version",
      dataIndex: "supported_ios_version",
      key: "supported_ios_version",
    },
  ];
  const dataSource=[
    {
        key: "1",
        date:"05/09/23",
        version:"2.0",
        name:"Name1",
        mandatoryOrOptional:"Mandatory",
        description:"Lorem Ipsum is simply . . . ",
        minimumVersion:"Version 1.0",
        supportedAndroidVersion:"Android 10",
        supportedIosVersion:"IOS 10"

    },
  ];
  
  const handleCreate = ()=>{
    navigate('/admin/version_control/create_version_control')
  }

  return (
  
    <>
    <div className='pt-4 ps-5 pe-5'>
      <div >
        <div className='heading'>Mobile App Version Control</div>
      </div>
      <div>
        <div className='d-flex justify-content-end'>
          <div>
            <CustomAddButton buttonText="Add Version"
            onClick={(e)=>{handleCreate()}}
             />
          </div>
        </div>
      </div>
      <div className='mt-4'>
        <div>
          <CustomTable columns={columns} dataSource={data}/>
        </div>
      </div>
    </div>
    </>
  )
}

export default VersionControl