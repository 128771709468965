import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ForgotPassword.scss";
import { Form } from "antd";
import AxiosInstance from "../../../utils/AxiosInstance";
import CustomInput from "../../../components/Admin/CustomInput";
import CustomButton from "../../../components/Admin/CustomButton";
import { FORGOT_PASSWORD_API, LOGIN_API } from "../../../Api/Api";
import { message } from "antd";
import { LOGIN_ROUTE } from "../../../Routes/Route";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [emailId, setEmailId] = useState();

  const handleForgotEmailBtnClick = async () => {
    try {
      const response = await AxiosInstance.post(FORGOT_PASSWORD_API, {
        email: emailId,
        callbackUrl: "localhost:3000/create_new_password",
      });

      if (response.status == 200) {
        message.success(response?.data?.message);
      } else {
        message.error("login error in else");
      }
    } catch (error) {
      console.log(error);
      if (error?.response?.data?.message === "Not found") {
        message.error("User not found");
      } else {
        message.error("login error");
      }
    }
  };

  return (
    <>
      <div className='forgot_password_page'>
        <div className='forgot_passoword_container pt-4 ps-5 pb-5 pe-4'>
          <div className='row'>
            <div className='col-md-5'>
              <div>
                <div>
                  <img
                    src='/images/Back.png'
                    className='back_btn'
                    onClick={() => {
                      navigate(LOGIN_ROUTE);
                    }}
                  />
                </div>
              </div>
              <div className='mt-4'>
                <div className='d-flex justify-content-center'>
                  <img src='/images/Lock.png' />
                </div>
              </div>
              <div className='d-flex justify-content-center mt-3'>
                <div className='heading'>Forgot Your Password ?</div>
              </div>
              <div className='mt-5'>
                <div>
                  <Form>
                    <Form.Item>
                      <CustomInput
                        placeholder='Enter Your Email Address'
                        onChange={(e) => {
                          setEmailId(e.target.value);
                        }}
                      />
                    </Form.Item>
                    <CustomButton
                      onClick={handleForgotEmailBtnClick}
                      buttonText='Send'
                      type='save'
                    />
                  </Form>
                </div>
              </div>
            </div>
            <div className='col-md-7'>
              <div className='mt-5'>
                <div className='d-flex justify-content-center'>
                  <img src='/images/NewPasswordImage.png' />
                </div>
              </div>
              <div className='mt-4'>
                <div
                  style={{ textAlign: "center", width: "610px" }}
                  className='login-text-two'>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book. It has survived
                  not only five centuries,
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
