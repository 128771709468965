import React from 'react'
import { Modal } from 'antd'
import './CustomModal.scss'
const CustomModal = ({title,open,onCancel,children}) => {
  return (
   <>
   <div>
   <Modal
        
        centered
        title={<div className='d-flex modal_heading justify-content-center'> <div>{title}</div></div>}
        style={{
          top: 20,
        }}
        open={open}
        onCancel={onCancel}
        footer={null}
      >
        {children}
        
      </Modal>
   </div>
   </>
  )
}

export default CustomModal