import { TimePicker } from 'antd';
import React from 'react';

const CustomTimePicker = ({onChange}) => {
    return (
        <>
          <div className="date_wrapper">
                    <TimePicker  
                    onChange={onChange}  
                    format="h:mm a"  
                    placeholder=''
                    style={{border:"none"}}/>
                    </div>
        </>
           
      
    );
}

export default CustomTimePicker;
