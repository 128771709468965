import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import JoditEditor from 'jodit-react';
import AxiosInstance from '../../../utils/AxiosInstance';
import { message } from 'antd';
import { useEffect } from 'react';
import {  CREATE_REFUND_POLICY_API, GET_REFUND_POLICY_API } from '../../../Api/Api';
import CustomButton from '../../../components/Admin/CustomButton';

const buttons = [
    'paragraph',
    'font',
      'bold',
    'italic',
    'underline',
    'strikethrough',
      'align',
    'ol',
    'ul',
    'outdent',
      'indent',
      
    'fontsize',
    'link',
      'image',
      'video',
      'table',
      
      
  ];
  
  const editorConfig = {
      readonly: false,
      toolbar: true,
      spellcheck: true,
      language: 'en',
      toolbarButtonSize: 'medium',
      toolbarAdaptive: false,
      showCharsCounter: false,
      showWordsCounter: false,
      showXPathInStatusbar: false,
      askBeforePasteHTML: false,
      askBeforePasteFromWord: false,
      //defaultActionOnPaste: "insert_clear_html",
      buttons: buttons,
      uploader: {
          insertImageAsBase64URI: true
      },
      width: '100%',
      height: '400px',
    
  };


const RefundPolicy = () => {
    const [data, setData] = useState('');
  const navigate = useNavigate()

  const handleCancel = ()=>{
    setData('')
  }

  const getData = ()=>{
    AxiosInstance.get(GET_REFUND_POLICY_API).then((res)=>{
      if(res.status === 200){
        if(res?.data){
          setData(res?.data?.refund_policy)
        }
      }else{
        throw new Error('Something went wrong')
      }
    }).catch((err)=>{
      message.error('Something went wrong')
    })
  }

  useEffect(()=>{
    getData()
  },[])


  const handleConfirm = ()=>{
    if(data){
      AxiosInstance.post(CREATE_REFUND_POLICY_API,{
        refundPolicy:data
      }).then((res)=>{
        if(res.status === 200){
          message.success('Refund policy added successfully')
          navigate('/admin/settings')
        }else{
          message.error('Something unexpected happend')
        }
      }).catch((Err)=>{
        message.error('Something unexpected happend')
      })
    
    
    }else{
      message.error('Please add the Refund policy')
    }
  }
  return (
    <div className='pt-4 ps-5 pe-5'>
      <div className='heading' style={{font:'lato',fontSize:'35px',fontWeight:'500',lineHeight:'23px'}}>Refund Policy</div>
      <div className='mt-4'>
        <JoditEditor
        value={data}
				config={editorConfig}
				onChange={(value) => setData(value)}/>
      </div>
      <div className='mt-4'>
            <div className="d-flex justify-content-center ">
              <div className='me-4'>
                <CustomButton buttonText="save" type="save" onClick={handleConfirm} height="49px" width="179px" htmlType="submit"/>
              </div>
              <div>
                <CustomButton buttonText="cancel"  type="cancel" onClick={handleCancel} height="49px" width="179px"/>
              </div>
            </div>
          </div>
    </div>
  );
            
     
}

export default RefundPolicy;
