import { Table } from 'antd';
import React from 'react';
import { ClockCircleOutlined } from '@ant-design/icons'

const TransactionTable = () => {

    const columns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",

        },
        {
            title: "Username",
            dataIndex: "username",
            key: "username",

        },
        {
            title: "Type of transcations",
            dataIndex: "type",
            key: "type",

        },
        {
            title: "Amount ",
            dataIndex: "amt",
            key: "amt",

        },
    ]


    const data = [
        {
            date: '08-06-2023',
            username: 'test',
            type: 'upi',
            amt: 1600
        },
        {
            date: '08-06-2023',
            username: 'test',
            type: 'cash',
            amt: 16090
        },
        {
            date: '08-06-2023',
            username: 'test',
            type: 'cash',
            amt: 16090
        },
        {
            date: '08-06-2023',
            username: 'test',
            type: 'cash',
            amt: 16090
        },
        {
            date: '08-06-2023',
            username: 'test',
            type: 'cash',
            amt: 16090
        },
        {
            date: '08-06-2023',
            username: 'test',
            type: 'cash',
            amt: 16090
        },
        {
            date: '08-06-2023',
            username: 'test',
            type: 'cash',
            amt: 16090
        },
        {
            date: '08-06-2023',
            username: 'test',
            type: 'cash',
            amt: 16090
        },
    ]
    return (
        <>
            <div style={{ width: '870px' }}>
                <div style={{ width: '870px', borderRadius: '8px', height: '44px', backgroundColor: '#FFD7DC', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', color: 'black', fontSize: '20px' }}>
                    <span style={{ paddingLeft: '19.5px' }}><ClockCircleOutlined /></span>
                    <span style={{ paddingLeft: '30px' }}> Transactions</span>
                </div>
                
                <Table  columns={columns} dataSource={data} pagination={false}  
                scroll={{y:'280px'}}
                 style={{ width: '100%', height: '450px' }} 
                 />
      
            </div>
        </>
    );
}

export default TransactionTable;
