import React, { useState } from 'react'
import CustomButtonGroup from '../../../components/Admin/CustomButtonGroup'
import { Button, DatePicker, Form, Input, TimePicker, Upload, message } from 'antd'
import CustomButton from '../../../components/Admin/CustomButton';
import './EnablerAd.scss'
import CustomDatePicker from '../../../components/Admin/CustomDatePicker';
import { useNavigate } from 'react-router-dom';
import AxiosInstance from '../../../utils/AxiosInstance';
import { ADD_NEWUSER_CAMPAIGN_API } from '../../../Api/Api';
import CustomTimePicker from '../../../components/Admin/CustomTimePicker';
const { Dragger } = Upload;
const { TextArea } = Input;

const NewUsers = () => {
  const navigate = useNavigate();
  const [enablerAdData, setEnablerAdData] = useState({
    name: "",
    activity: "",
    offerFromDate: "",
    offerToDate: "",
    timeFrom: "",
    timeTo: "",
    description: "",
    imageUrl: "",
    coin: ""
  })


  /////used to set disable the date in date picker
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const handleFromDateChange = (date) => {
    setFromDate(date);
  };

  const handleToDateChange = (date) => {
    setToDate(date);
  };

  const disabledFromDate = (currentDate) => {
    // Disable dates after the selected "To" date in the "From" date picker
    if (toDate && currentDate && currentDate >= toDate) {
      return true;
    }

    return false;
  };

  const disabledToDate = (currentDate) => {
    // Disable dates before the selected "From" date in the "To" date picker
    if (fromDate && currentDate && currentDate <= fromDate) {
      return true;
    }

    return false;
  };

  const customValidator = (_, value) => {
    const num = parseInt(value, 10);
    if (isNaN(num) || num < 1 || num > 10) {
      return Promise.reject(new Error('Please enter a number between 1 and 5'));
    }
    return Promise.resolve();
  };


  const handlePreview = (file) => {
    // Prevent the default behavior, which would trigger form submission
    file.preventDefault()
  };

  const validateFileSize = (file) => {
    const maxSize = 10 * 1024 * 1024; // 10 MB (adjust the size limit as needed)
    if (file.size > maxSize) {
      message.error('File size must be within 10MB.');
      return false; // Prevent file from being uploaded
    }
    return true; // Proceed with the upload
  };

  const addEnablerAd = async () => {

    let timefromDateObj = new Date(enablerAdData.timeFrom);
    let timetoDateObj = new Date(enablerAdData.timeTo);
    let datefromDateObj = new Date(enablerAdData.offerFromDate);
    let datetoDateObj = new Date(enablerAdData.offerToDate);

    function extractDate(dateObj) {
      const day = dateObj.getUTCDate();
      const month = dateObj.toLocaleString('default', { month: 'short' });
      const year = dateObj.getUTCFullYear();


      return { day, month, year };
    }

    const extractedDate1 = extractDate(datefromDateObj);
    const extractedDate2 = extractDate(datetoDateObj);

    if (extractedDate1.year === extractedDate2.year &&
      extractedDate1.month === extractedDate2.month &&
      extractedDate1.day === extractedDate2.day) {
      if (timefromDateObj >= timetoDateObj) {
        message.error('Offer Period Time to can not be lesserthan Offer Period Time From ')
      } else {
        datefromDateObj.setHours(timefromDateObj.getHours());
        datefromDateObj.setMinutes(timefromDateObj.getMinutes());
        datefromDateObj.setSeconds(timefromDateObj.getSeconds());
        datetoDateObj.setHours(timetoDateObj.getHours());
        datetoDateObj.setMinutes(timetoDateObj.getMinutes());
        datetoDateObj.setSeconds(timetoDateObj.getSeconds());
        try {
          const formData = new FormData();
          formData.append("schemeName", enablerAdData.name);
          formData.append("activity", enablerAdData.name);
          formData.append("startDate", datefromDateObj);
          formData.append("endDate", datetoDateObj);
          formData.append("description", enablerAdData.description);
          formData.append("imageUrl", enablerAdData.imageUrl);
          formData.append("extraCoin", enablerAdData.coin)
          const response = await AxiosInstance.post(ADD_NEWUSER_CAMPAIGN_API, formData);

          if (response.status === 201) {
            message.success("campaign created");
            navigate("/admin/campaign_management")
          }
        } catch (error) {
          message.error(error.response.data.message);

        }
      }
    } else {
      datefromDateObj.setHours(timefromDateObj.getHours());
      datefromDateObj.setMinutes(timefromDateObj.getMinutes());
      datefromDateObj.setSeconds(timefromDateObj.getSeconds());
      datetoDateObj.setHours(timetoDateObj.getHours());
      datetoDateObj.setMinutes(timetoDateObj.getMinutes());
      datetoDateObj.setSeconds(timetoDateObj.getSeconds());
      try {
        const formData = new FormData();
        formData.append("schemeName", enablerAdData.name);
        formData.append("activity", enablerAdData.name);
        formData.append("startDate", datefromDateObj);
        formData.append("endDate", datetoDateObj);
        formData.append("description", enablerAdData.description);
        formData.append("imageUrl", enablerAdData.imageUrl);
        formData.append("extraCoin", enablerAdData.coin)
        const response = await AxiosInstance.post(ADD_NEWUSER_CAMPAIGN_API, formData);
        console.log("here is the response after adding", response);
        if (response.status === 201) {
          message.success("campaign created");
          navigate("/admin/campaign_management")
        }
      } catch (error) {
        message.error(error.response.data.message);


      }
    }


  }

  const handleCancel = () => {
    navigate('/admin/campaign_management')
  }
  return (
    <>
      <div className='pt-4 ps-5 pe-5'>
        <div>
          <div className='heading'>Campaign Management</div>
        </div>
        <div className='mt-3'>
          <div>
            <CustomButtonGroup />
          </div>
        </div>
        <div className='mt-4'>
          <div>
            <Form onFinish={addEnablerAd} encType="multipart/form-data">
              <div className='d-flex justify-content-between'>
                <div>
                  <div className='form_label'> Name of the Scheme</div>
                  <div>
                    <Form.Item name="name" rules={[{ required: true, message: "Please Enter the Scheme Name" }, { pattern: /^(?!^\s+$)[a-zA-Z\s]+$/, message: "invalid name" }, { max: 50, message: 'maximum character is 50' }]}>
                      <Input style={{ backgroundColor: "#F1F1F1", height: "54px", width: "631px", borderRadius: "6px", border: "none" }} onChange={(e) => {
                        setEnablerAdData({ ...enablerAdData, name: e.target.value })
                      }} />
                    </Form.Item>
                  </div>
                </div>
                {/* <div>
              <div className='form_label'>Activity</div>
                <div>
                  <Form.Item name="activity">
                  <Input style={{backgroundColor:"#F1F1F1",height:"54px",width:"631px",borderRadius:"6px",border:"none"}} onChange={(e)=>{setEnablerAdData({...enablerAdData,activity:e.target.value})}}/>
             
                  </Form.Item>
                </div>
              </div> */}
              </div>
              <div className='mt-3'>
                <div className='form_label'>Upload Offer Image</div>
                <div className='mt-1'>
                  <Form.Item name="image" rules={[{ required: true, message: 'Please upload an image' }]}>
                    <Dragger beforeUpload={true} accept={['image/jpeg', 'image/jpg', 'image/png']} maxCount={1} onChange={
                      (file) => {
                        if (validateFileSize(file.file)) { // Check the return value of validateFileSize
                          setEnablerAdData({ ...enablerAdData, imageUrl: file.fileList[0].originFileObj });
                        }

                      }}>
                      <p className="ant-upload-drag-icon">
                        {/* <InboxOutlined /> */}
                        <img src='/images/cloud-upload.png' />
                      </p>
                      <p className="ant-upload-text">Drag or drop files here</p>
                      <div className='upload-button-wrapper'>
                        <Button >upload Offer image</Button>
                      </div>
                    </Dragger>
                  </Form.Item></div>
              </div>
              <div className='mt-3 '>
                <div className='d-flex justify-content-between'>
                  <div>
                    <div className='form_label'>Offer Period Date From</div>
                    <div>
                      <Form.Item name="fromDate" rules={[{ required: true, message: 'Please select a date' }]}>
                        <CustomDatePicker
                          disabledDate={disabledFromDate}
                          onChange={(e) => {
                            handleFromDateChange(e)
                            console.log("date", e);
                            setEnablerAdData({ ...enablerAdData, offerFromDate: e })
                          }}
                        />
                      </Form.Item>

                    </div>
                  </div>
                  <div>
                    <div className='form_label'>Offer Period Time From</div>
                    <div>
                      <Form.Item name='from_time' rules={[{ required: true, message: 'Please select time' }]}>
                        <CustomTimePicker
                          onChange={(e) => {
                            console.log(e);
                            setEnablerAdData({ ...enablerAdData, timeFrom: e })
                          }}
                        />

                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <div className='form_label'>Offer Period Date To</div>
                    <div>
                      <Form.Item name="toDate" rules={[{ required: true, message: 'Please select a date' }]}>
                        <CustomDatePicker
                          disabledDate={disabledToDate}
                          onChange={
                            (e) => {
                              handleToDateChange(e)
                              setEnablerAdData({ ...enablerAdData, offerToDate: e })
                            }
                          } />
                      </Form.Item>

                    </div>
                  </div>

                  <div>
                    <div className='form_label'>Offer Period Time To</div>
                    <div>
                      <Form.Item name='to_time' rules={[{ required: true, message: 'Please select a time' }]}>
                        <CustomTimePicker
                          onChange={(e) => {
                            setEnablerAdData({ ...enablerAdData, timeTo: e })
                          }}
                        />

                      </Form.Item>
                    </div>
                  </div>
                  <div>
                    <div className='form_label'>Extra Coin</div>
                    <div>
                      <Form.Item name='coin' rules={[{ required: true, message: 'Please add the coin' }, { pattern: /^\d+$/, message: "Invalid coin" }, { validator: customValidator }]}>
                        <Input style={{ backgroundColor: "#F1F1F1", height: "54px", width: "185px", borderRadius: "6px", border: "none" }} onChange={(e) => {
                          setEnablerAdData({ ...enablerAdData, coin: e.target.value })
                        }} />
                      </Form.Item>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className='form_label'> Description</div>
                <div>
                  <Form.Item name="description" rules={[{ required: true, message: 'Please add description' }, { max: 250, message: 'Maximum character allowed is 250' }, { min: 3, message: 'Minimum required character is 3' }]}>
                    <TextArea style={{ backgroundColor: "#F1F1F1", border: "none" }} rows={5} onChange={(e) => { setEnablerAdData({ ...enablerAdData, description: e.target.value }) }} />
                  </Form.Item>
                </div>
              </div>
              <div className='d-flex justify-content-center'>
                <div className='me-2'><CustomButton type="save" buttonText="Save" width="179px" height="49px" htmlType="submit" /></div>
                <div><CustomButton type="cancel" onClick={handleCancel} buttonText="Cancel" width="179px" height="49px" /></div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </>
  )
}

export default NewUsers