
import { Card } from 'antd';
import Title from 'antd/es/typography/Title';
import React from 'react';
import myImage from '../../../styles/Images/money and pie chart.svg';
import coin from '../../../styles/Images/standing dollar coin front.svg'

const TotalCoinsale = () => {
    return (
       <>
       <div>
        <Card style={{width:'424.61px',height:'420px', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)'}}>
        <Title style={{ fontFamily: 'lato', lineHeight: '12px', fontSize: '20px', fontWeight: '600' }}>Total Coin Sale</Title>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
         
          <img src={myImage} alt="Image" style={{ width: '186px', height: '245px', margin: '10px' }} />
          <div style={{ width: '145.57px',borderRadius:'31px', height: '57.43px', backgroundColor: '#f1f1f1', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black', fontSize: '20px' }}>
            <img src={coin} alt="" style={{width:"44px",height:'38px'}}/>
            150k
          </div>
        </div>
            
        </Card>
       </div>
       </>
    );
}

export default TotalCoinsale;
