import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./CreateNewPassword.scss";
import CustomPassword from "../../../components/Admin/CustomPassword";
import CustomButton from "../../../components/Admin/CustomButton";
import { Form, message } from "antd";
import { RESET_PASSWORD_API } from "../../../Api/Api";
import AxiosInstance from "../../../utils/AxiosInstance";
import { LOGIN_ROUTE } from "../../../Routes/Route";

const CreateNewPassword = () => {
  let { id } = useParams(); //getting token id id from url
  const navigate = useNavigate();
  const [newPassword, setNewPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();

  const handlePasswordChange = async () => {
    try {
      if (newPassword !== confirmPassword) {
        message.error("Passwords doesnot match");
      } else {
        const response = await AxiosInstance.post(RESET_PASSWORD_API, {
          token: id,
          password: newPassword,
        });
        if (response.status === 200) {
          message.success("Password updated successfully");
          navigate(LOGIN_ROUTE);
        } else {
          message.error("Error while updating data");
        }
      }
    } catch (error) {
      console.log(error);
      message.error("Error while saving password");
    }
  };

  return (
    <>
      <div className='create_new_password_page'>
        <div className='new_password_container'>
          <div className='card pt-5 pb-4 ps-4 pe-4 forgot_password_card'>
            <div className='d-flex justify-content-center'>
              <div className='heading'>Create New Password</div>
            </div>
            <div className='mt-3'>
              <div>
                <Form>
                  <Form.Item>
                    <CustomPassword
                      placeholder='New Password'
                      onChange={(e) => {
                        setNewPassword(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <CustomPassword
                      placeholder='Confirm New Password'
                      onChange={(e) => {
                        setConfirmPassword(e.target.value);
                      }}
                    />
                  </Form.Item>
                  <Form.Item>
                    <CustomButton
                      buttonText='Save'
                      type='save'
                      onClick={handlePasswordChange}
                    />
                  </Form.Item>
                  <Form.Item>
                    <CustomButton
                      buttonText='Cancel'
                      type='cancel'
                      onClick={() => {
                        navigate(LOGIN_ROUTE);
                      }}
                    />
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateNewPassword;
