import { Table } from 'antd'
import React from 'react'

const CustomTable = ({columns,dataSource,onRow}) => {
   let  c=columns.map((item)=>{
        item.align="center";
        return item;
    })
  return (
    <>
    <div className='table_wrapper'>
        <Table columns={columns} dataSource={dataSource} pagination={false} onRow={onRow}/>
    </div>
    </>
  )
}

export default CustomTable