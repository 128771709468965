/* Login Routes start*/
export const LOGIN_ROUTE="/login";
export const FORGOT_PASSWORD_ROUTE="/forgot_password";
export const CREATE_NEW_PASSWORD_ROUTE="/create_new_password/:id";
/*Login Routes end*/

/*admin dashboard Routes start */

export const ADMIN_HOME_ROUTE="/admin"

/*admin dasboard Routes end */