import { Form, message } from 'antd'
import React, { useEffect, useState } from 'react'
import CustomInput from '../../../components/Admin/CustomInput'
import {Input,Select} from 'antd'
import CustomButton from '../../../components/Admin/CustomButton';
import AxiosInstance from '../../../utils/AxiosInstance';
import { CREATE_ADMIN_USER_API, GET_ROLES_API } from '../../../Api/Api';
import { useNavigate } from 'react-router-dom';
import CustomSelect from '../../../components/Admin/CustomSelect';
const { TextArea } = Input;
const UserManagementCreateNewUser = () => {
  const [addForm] = Form.useForm()
  const [roles,setRoles]=useState([]);
  const [userData,setUserData]=useState({
    name:"",
    role:"",
    emailId:"",
    mobileNumber:"",
    description:""
  });
  const navigate=useNavigate();
  const getRoles=async()=>{
    try {
      const response=await AxiosInstance.get(GET_ROLES_API);
    
      if(response.status===200){
        const roles_temp=response?.data?.map((item)=>{
          return { value:item.id,label:item.role_name}
        });
        setRoles(roles_temp);
      }
    } catch (error) {
      
    }
  }
  const addUser=async()=>{
    console.log("inside add user");
    try {
      const response=await AxiosInstance.post(CREATE_ADMIN_USER_API,{
        name:userData.name,
        roleId:userData.role,
        email:userData.emailId,
        countryCode:"+91",
        mobileNumber:userData.mobileNumber,
        description:userData.description
      });
      if(response.status===200){
        message.success("User added");
        navigate("/admin/user_management_user")
      }
    } catch (error) {
      message.error(error.response.data.message)
    }
  }

   const handleRoleChange = (value) => {
    setUserData({ ...userData, role: value });
    addForm.setFieldsValue({role:value})
   
  };
  const [hasError, setHasError] = useState(false);

  const validateMobileNumber = (_, value) => {
    // The regular expression to check if the number is "0000000000"
    const regex = /^0{10}$/;
    if (regex.test(value)) {
      setHasError(true);
      return Promise.reject(new Error('Invalid mobile number'));
    } else {
      setHasError(false);
      return Promise.resolve();
    }
  };

  const handleCancel = ()=>{
     
    navigate('/admin/user_management_user')
  }

  useEffect(()=>{
    getRoles();
  },[])
  return (
    <>
    <div className='pt-4 ps-5 pe-5'>
      <div>
        <Form 
        form={addForm}
        onFinish={addUser}>
          <div className="d-flex justify-content-between">
            <div>
              <div className='form_label'>Name</div>
              <div className='mt-1'>
              <Form.Item name="name" rules={[{required:true,message:"Please Enter Name"},{pattern:/^(?!^\s+$)[a-zA-Z\s]+$/,message:"invalid name"},{max:50,message:'Name can not be more than 50 characters'}]}>
              <CustomInput height="54px" onChange={(e)=>{setUserData({...userData,name:e.target.value})}}/>
              </Form.Item>    
              </div>
          
            </div>
            <div>
              <div className='form_label'>Role</div>
              <div className='mt-1'>
              <Form.Item name="role" rules={[{required:true,message:"select a role"}]} >
                <div className='select_wrapper' >
                <Select
                style={{
                  borderRadius:'6px',
                 backgroundColor:"#F1F1F1",
                    width:"298px"
                }}
                onChange={handleRoleChange}
                options={roles}
              />
                </div>
                
              </Form.Item>    
              
              </div>
            </div>
            <div>
              <div className='form_label'>Email ID</div>
              <div className='mt-1'>
              <Form.Item name="email" rules={[{required:true,message:"Please enter an email id"},{pattern:/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,message:"enter a valid email address"}]}>
              <CustomInput height="54px" onChange={(e)=>{setUserData({...userData,emailId:e.target.value})}}/>
              </Form.Item>    
              </div>
            </div>
            <div>
              <div className='form_label'> Mobile Number</div>
              <div className='mt-1'>
              <Form.Item name="mobile" rules={[{required:true,message,message:"Please enter mobile number"},{pattern:/^[0-9]{10}$/,message:"enter a valid mobile number"},{ validator: validateMobileNumber },]}
              validateStatus={hasError ? 'error' : ''}
              help={hasError ? 'Invalid mobile number' : null}> 
              <CustomInput height="54px" onChange={(e)=>{setUserData({...userData,mobileNumber:e.target.value})}}/>
              </Form.Item>    
              </div>
            </div>
          </div>
          <div>
            <div className='form_label'>Descriptions</div>
            <div className='mt-1'>
            <Form.Item name="description" rules={[{required:true,message:"Please enter description"},{pattern:/^(?!^\s+$)[a-zA-Z\s]+$/,message:"invalid name"}]}>
              <TextArea rows={4} style={{backgroundColor:"#F1F1F1",border:"none"}} onChange={(e)=>{ setUserData({...userData,description:e.target.value})}}/>
            </Form.Item>
            </div>

          </div>
          <div className='mt-4'>
            <div className="d-flex justify-content-center ">
              <div className='me-4'>
                <CustomButton buttonText="save" type="save" height="49px" width="179px" htmlType="submit"/>
              </div>
              <div>
                <CustomButton buttonText="cancel" type="cancel" onClick={handleCancel} height="49px" width="179px"/>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
    </>
  )
}

export default UserManagementCreateNewUser