import React, { useEffect, useState } from 'react'
import CustomAddButton from '../../../components/Admin/CustomAddButton'
import { BiEditAlt } from "react-icons/bi";
import { RiDeleteBinLine } from "react-icons/ri";
import CustomTable from '../../../components/Admin/CustomTable';
import { useNavigate } from 'react-router-dom';
import AxiosInstance from '../../../utils/AxiosInstance';
import { DELETE_ADMIN_USER_API, GET_ADMIN_USERS_API } from '../../../Api/Api';
import { message } from 'antd';
import moment from 'moment';
const UserManagementUser = () => {
  const navigate=useNavigate();
  const [adminUsers,setAdminUsers]=useState([]);
  const [reload,setReload]=useState(false);
  const columns=[
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title:"Role",
      dataIndex:"role",
      key:"role"
    },
    {
      title:"Created Date",
      dataIndex:"createdDate",
      key:"createdDate"
    },
    {
      title:"Email ID",
      dataIndex:"emailId",
      key:"emailId"
    },
    {
      title:"Phone Number",
      dataIndex:"phoneNumber",
      key:"phoneNumber"
    },
    {
      title:"Last Login Time Stamp",
      dataIndex:"lastLogin",
      key:"lastLogin"
    },
    {
      dataIndex: "action",
      render: (data,record) => (
        <>
          <div className="d-flex justify-content-center" >
            <BiEditAlt
              className="icons"
             onClick={()=>{
              console.log(record);
              navigate(`/admin/user_management_edit_user/${record.id}`)
             }}
           
            />
            <RiDeleteBinLine className="icons ms-3 " onClick={()=>{deleteAdminUser(record)}} />
          </div>
        </>
      ),
    },
  ];

  const getAdminUsers=async()=>{
    try {
      const response=await AxiosInstance.get(GET_ADMIN_USERS_API);
      if(response.status===200){
        let admin_users_temp=response?.data?.map((item)=>{
          return {
            name:item.name,
            role:item.roles.role_name,
            createdDate:moment(new Date(item.created_at)).format("DD-MM-YYYY"),
            emailId:item.email,
            phoneNumber:item.phone_number,
            lastLogin:moment(new Date(item.last_login)).format("DD-MM-YYYY"),
            description:item.description,
            id:item.id
          }
        });
        setAdminUsers(admin_users_temp)
      }
    } catch (error) {
      
    }
  }

  const deleteAdminUser=async(record)=>{   
    try {
      const response=await AxiosInstance.post(DELETE_ADMIN_USER_API,{
        id:record.id
      });
      if(response.status===200){
        message.success("user deleted");
        setReload(!reload);
      }
    } catch (error) {
      
    }
  }
  useEffect(()=>{
    getAdminUsers();
  },[reload])

  return (
    <>
    <div className='pt-4 ps-5 pe-5'>
      <div>
        <div className='heading'>Roles & User Management : User Master</div>
      </div>
      <div className='mt-3'>
        <div className='d-flex justify-content-end'>
            <div>
              <CustomAddButton buttonText="New User" onClick={()=>{navigate("/admin/user_management_create_new_user")}}/>
            </div>
        </div>
      </div>
      <div className='mt-4'>
        <div>
          <CustomTable columns={columns} dataSource={adminUsers}/>
        </div>
      </div>
    </div>
    </>
  )
}

export default UserManagementUser