import './App.css';
import {Routes,Route, Navigate} from 'react-router-dom'
import Login from './pages/Admin/Login/Login';
import ForgotPassword from './pages/Admin/Login/ForgotPassword';
import CreateNewPassword from './pages/Admin/Login/CreateNewPassword';
import './styles/override.scss'
import AdminLayout from './layout/admin/AdminLayout';
import Dashboard from './pages/Admin/Dashboard/Dashboard';
import MobileUserActivities from './pages/Admin/MobileUserActivities/MobileUserActivities';
import EnablerManagement from './pages/Admin/EnablerManagement/EnablerManagement';
import CampaignManagement from './pages/Admin/CampaignManagement/CampaignManagement';
import Reports from './pages/Admin/Reports/Reports';
import Grievance from './pages/Admin/Grievance/Grievance';
import Settings from './pages/Admin/Settings/Settings';
import UserManagement from './pages/Admin/UserManagement/UserManagement';
import SubscriptionManagement from './pages/Admin/SubscriptionManagement/SubscriptionManagement';
import MasterData from './pages/Admin/MasterData/MasterData';
import EnablerCategoryMaster from './pages/Admin/EnablerCategoryMaster/EnablerCategoryMaster';
import VersionControl from './pages/Admin/VersionControl/VersionControl';
import TermsAndConditions from './pages/Admin/TermsAndConditions/TermsAndConditions';
import PrivacyPolicy from './pages/Admin/PrivacyPolicy/PrivacyPolicy';
import EnablerAd from './pages/Admin/CampaignManagement/EnablerAd';
import ExistingUsers from './pages/Admin/CampaignManagement/ExistingUsers';
import NewUsers from './pages/Admin/CampaignManagement/NewUsers';
import CreateNewPlan from './pages/Admin/SubscriptionManagement/CreateNewPlan';
import Hobbies from './pages/Admin/Hobbies/Hobbies';
import ProtecedRoute from './components/ProtectedRoute';
import UserManagementUser from './pages/Admin/UserManagement/UserManagementUser';
import { ADMIN_HOME_ROUTE, CREATE_NEW_PASSWORD_ROUTE, FORGOT_PASSWORD_ROUTE, LOGIN_ROUTE } from './Routes/Route';
import UserManagementCreateNewUser from './pages/Admin/UserManagement/UserManagementCreateNewUser';
import UserManagementEditUser from './pages/Admin/UserManagement/UserManagementEditUser';
import EditNewPlan from './pages/Admin/SubscriptionManagement/EditNewPlan';
import CreateVersionControl from './pages/Admin/VersionControl/CreateVersionControl';
import RefundPolicy from './pages/Admin/RefundPolicy/RefundPolicy';

function App() {
  return (
    <>
    <Routes>
      <Route path={LOGIN_ROUTE} element={<Login/>}/>
      <Route path={FORGOT_PASSWORD_ROUTE} element={<ForgotPassword/>}/>
      <Route path={CREATE_NEW_PASSWORD_ROUTE} element={<CreateNewPassword/>}/>
      <Route path='/' element={<Navigate to={ADMIN_HOME_ROUTE}/>}/>
      <Route path={ADMIN_HOME_ROUTE} element={<ProtecedRoute/>}>
        <Route element={<AdminLayout/>}>
        <Route index element={<Dashboard/>}/>
        <Route path='moblile_user_activities' element={<MobileUserActivities/>}/>
        <Route path='user_management_roles' element={<UserManagement/>}/>
        <Route path='user_management_user' element={<UserManagementUser/>}/>
        <Route path="user_management_create_new_user" element={<UserManagementCreateNewUser/>}/>
        <Route path='user_management_edit_user/:id' element={<UserManagementEditUser/>}/>
        <Route path='reports' element={<Reports/>}/>
        <Route path='enabler_management' element={<EnablerManagement/>}/>
        <Route path='campaign_management' element={<CampaignManagement/>}/>
        
        //for development purpose only
        <Route path='enabler_ad' element={<EnablerAd/>}/>
        <Route path='new_users' element={<NewUsers/>}/>
        <Route path='existing_users' element={<ExistingUsers/>}/>
        //for development purpose only


        


        <Route path='grievance' element={<Grievance/>}/>
        <Route path='settings' element={<Settings/>}/>
      
        {/* for development purpose only*/}
        <Route path='create-new-plan' element={<CreateNewPlan/>}/>
        <Route path='edit-plan/:id' element={<EditNewPlan/>}/>
        {/* for development purpose only*/}
        <Route path='subscription_management' element={<SubscriptionManagement/>}>
      
          <Route path='create-new-plan' element={<CreateNewPlan/>}/>
          <Route path='edit-plan/:id' element={<EditNewPlan/>}/>
        
        </Route>
        {/*for development purpose only */}
          <Route path='hobbies' element={<Hobbies/>}/>
        {/*for development purpose only */}

        <Route path='master_data' element={<MasterData/>}/>
        <Route path='enabler_category_master' element={<EnablerCategoryMaster/>}/>
        <Route path='version_control' element={<VersionControl/>}/>
        <Route path='version_control/create_version_control' element={<CreateVersionControl/>}/>
        <Route path='terms_and_conditions' element={<TermsAndConditions/>}/>
        <Route path='privacy_policy' element={<PrivacyPolicy/>}/>
        <Route path='refund_policy' element={<RefundPolicy/>}/>
        </Route>
      

      </Route>
    </Routes>
    </>
  );
}

export default App;
