import { Button } from 'antd'
import React from 'react'
import { AiOutlinePlus } from "react-icons/ai";

const CustomAddButton = ({buttonText,onClick}) => {
  return (
    <>
    <Button
                className="button"
                style={{
                  height: "49px",
                  // width: "140px",
                  // paddingLeft: "2px",
                  // paddingRight: "2px",
                  paddingBottom: "2px",
                  backgroundColor:"#C62137",
                  color:"white"

                }}
                icon={
                  <AiOutlinePlus
                    style={{ fontSize: "24px", marginBottom: "2px" }}
                  />
                }
                onClick={onClick}
              >
                <span
                  style={{
                    fontSize: "20px",
                    marginLeft: "2px",
                    marginRight: "3px",
                    marginTop: "1.5px",
                  }}
                >
                  {buttonText}
                </span>
              </Button>
    </>
  )
}

export default CustomAddButton