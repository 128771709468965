import { DatePicker, Form, message } from 'antd';
import React, { useEffect, useState, version } from 'react';
import CustomButton from '../../../components/Admin/CustomButton';
import CustomInput from '../../../components/Admin/CustomInput';
import CustomSelect from '../../../components/Admin/CustomSelect';
import TextArea from 'antd/es/input/TextArea';
import AxiosInstance from '../../../utils/AxiosInstance';
import { CREATE_NEW_VERSION_API, GET_VERSION_API, LIST_ANDROID_VERSION_API, LIST_IOS_VERSION_API } from '../../../Api/Api';
import { useNavigate } from 'react-router-dom';

const CreateVersionControl = () => {
    const [addForm] = Form.useForm()
    const [versions,setVersions] = useState([])
    const [Android,setAndroid] = useState([]);
    const [ios,setIOS] = useState([])
    const navigate = useNavigate()
    const [data, setData] = useState({
        name: "",
        date: "",
        androidVersion:'',
        iosVersion: "",
        isMandatory: '',
        minimumIosVersion:"",
        minimumAndriodVersion: "",
        supportedAndroidVersion: "",
        supportedIosVersion: "",
        description: ""

    })
    const type = [
        {
            label: 'Mandatory',
            value: '1'
        },
        {
            label: 'Optional',
            value: '2'
        }
    ]
    const getVersion  = ()=>{
        AxiosInstance.get(GET_VERSION_API).then((res)=>{
            if(res.status === 200){
                let array = res?.data?.map((obj)=>{
                    return {...obj,label:obj.name, value:obj.name}
                })
                setVersions(array)
            }
        }).catch((err)=>{
            console.log(err);
        })
    }

    const getAndroidVersion = ()=>{
        AxiosInstance.get(LIST_ANDROID_VERSION_API).then((res)=>{
            if(res.status === 200){
              let array = res?.data?.map((obj)=>{
                    return {...obj,label:obj.android_version_name, value:obj.android_version_name}
                })
              
                setAndroid(array)
            }else{
                throw new Error('something went wrong')
            }
        }).catch((err)=>{
            console.log(err);
        })
    }   

    const getIOSversion = ()=>{
        AxiosInstance.get(LIST_IOS_VERSION_API).then((res)=>{
            if(res?.status === 200){
                let array = res?.data?.map((obj)=>{
                    return {...obj,label:obj.ios_version, value:obj.ios_version}
                })
              
                setIOS(array)
            }else{
                throw new Error('something went wrong')
            }
        }).catch((err)=>{
            console.log(err);
        })
    }

    const handleCreate = ()=>{
        
        AxiosInstance.post(CREATE_NEW_VERSION_API,{
            name: data.name,
            date: data.date,
            androidVersion: data.androidVersion,
            iosVersion:data.iosVersion,
            isMandatory: parseInt(data.isMandatory),
            minimumIosVersion: data.minimumIosVersion,
            minimumAndroidVersion:data.minimumAndriodVersion,
            // supportedAndroidVersion: data.supportedAndroidVersion,
            // supportedIosVersion: data.supportedIosVersion,
            supportedAndroidVersion: 'Android 13.0',
            supportedIosVersion: 'IOS 17',
            description:data.description
        }).then((res)=>{
            if(res?.status ===200){
                message.success('Version created successfully')
                navigate('/admin/version_control')
            }  
        }).catch((Err)=>{
            if(Err.response.data.message === 'Already exist'){
                message.error(`Same version can't be used again`)
            }
          
           
        })
    }

    const handleCancel = ()=>{
        addForm.resetFields()
        navigate('/admin/version_control')
    }

    useEffect(()=>{
        // getVersion()
        getIOSversion()
        getAndroidVersion()
    },[])

    return (
        <>
            <div className='pt-4 ps-5 pe-5'>
                <div>
                    <div className='create-newplan-heading'>Create New Version</div>
                </div>
                <div className='mt-4'>
                    <div>
                        <Form
                            form={addForm}
                            onFinish={handleCreate}
                        >
                            <div>
                                <div className='d-flex justify-content-between'>
                                    <div>
                                        <div className='form-label-newplan'>Name</div>
                                        <div className='mt-1'>
                                            <Form.Item name='Name' rules={[{ required: true, message: 'Please provide name' },{max:50,message:'Name cannot be loger than 50 characters'}]}>
                                                <CustomInput height="54px" width="299px" backgroundColor='F1F1F1'
                                                onChange={(e) => { setData({ ...data, name: e.target.value }) }}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='form-label-newplan'>Date</div>
                                        <div className='mt-1'>
                                            <Form.Item name='Date' rules={[{ required: true, message: 'Please provide a date' }]} >
                                                <DatePicker style={{ height: "54px", width: "298px", backgroundColor: "#F1F1F1", border: "none", borderRadius: "6px" }}
                                                onChange={(e) => { setData({ ...data, date: e }) }}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    
                                    <div>
                                        <div className='form-label-newplan'>Mandatory/Optional</div>
                                        <div className='mt-1'>
                                            <Form.Item name='isMandatory' rules={[{ required: true, message: 'Please provide mandatory or optional' }]} >
                                                <CustomSelect data={type} onChange={(value) => { setData({ ...data, isMandatory: value }) }} />
                                            </Form.Item>
                                        </div>
                                    </div>



                                </div>
                                <div className=" mt-5 d-flex justify-content-between ">
                                <div>
                                        <div className='form-label-newplan'>Android Version</div>
                                        <div className='mt-1'>
                                            <Form.Item name='AndroidVersion' rules={[{ required: true, message: 'Please provide  android version name' }]} >
                                            <CustomInput height="54px" width="299px" backgroundColor='F1F1F1'
                                                onChange={(e) => { setData({ ...data, androidVersion: e.target.value }) }}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='form-label-newplan'>IOS Version</div>
                                        <div className='mt-1'>
                                            <Form.Item name='IOSVersion' rules={[{ required: true, message: 'Please provide  IOS version name' }]} >
                                            <CustomInput height="54px" width="299px" backgroundColor='F1F1F1'
                                                onChange={(e) => { setData({ ...data, iosVersion: e.target.value }) }}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='form-label-newplan'>Minimum Android Version</div>
                                        <div className='mt-1'>
                                            <Form.Item name='Minimum version' rules={[{ required: true, message: 'Please provide Minimum version required' }]}>
                                                <CustomInput height="54px" width="299px" backgroundColor='F1F1F1'
                                                onChange={(e) => { setData({ ...data, minimumAndriodVersion: e.target.value }) }}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div>   
                                        <div className='form-label-newplan'>Minimum IOS Version</div>
                                        <div className='mt-1'>
                                            <Form.Item name='Minimum IOSversion' rules={[{ required: true, message: 'Please provide Minimum version required' }]}>
                                                <CustomInput height="54px" width="299px" backgroundColor='F1F1F1'
                                                onChange={(e) => { setData({ ...data, minimumIosVersion: e.target.value }) }}
                                                />
                                            </Form.Item>
                                        </div>
                                    </div>
                                </div>
                               

                                <div className=" mt-5 d-flex justify-content-start">

                                    <div style={{marginRight: '90px'}}>

                                        <div className='form-label-newplan'>Supported Android Version</div>
                                        <div className='mt-1'>
                                            <Form.Item name='SupportedAndroidVersion' 
                                            // rules={[{ required: true, message: 'Please provide the supported version' }]}
                                             >
                                                <CustomSelect data={Android} onChange={(value) => { setData({ ...data, supportedAndroidVersion: value }) }} />
                                            </Form.Item>
                                        </div>
                                    </div>
                                    <div>

                                        <div className='form-label-newplan'>Supported IOS Version</div>
                                        <div className='mt-1'>
                                            <Form.Item name='Ios version' 
                                            // rules={[{ required: true, message: 'Please provide supported IOS version' }]}
                                             >
                                                <CustomSelect data={ios} onChange={(value) => { setData({ ...data, supportedIosVersion: value }) }}/>
                                            </Form.Item>
                                        </div>
                                    </div>



                                </div>
                                <div>
                                    <div className='form-label-newplan'>Description</div>
                                    <div className='mt-1'>
                                        <Form.Item name='Description' rules={[{ required: true, message: 'Please provide Description' },{min:50, message:'Describe in 50 characters'},{max:250, message:'Maximum 250 characters allowed'}]}>
                                            <TextArea rows={5} style={{ border: 'none', borderRadius: '6px', backgroundColor: '#F1F1F1' }}
                                            onChange={(e) => { setData({ ...data, description: e.target.value }) }} />
                                        </Form.Item>
                                    </div>
                                </div>

                                <div className='mt-5'>
                                    <div className="d-flex justify-content-center">
                                        <div className='me-4'>
                                            <CustomButton buttonText="Save" type="save" width="179px" height="49px" htmlType="submit" />
                                        </div>
                                        <div>
                                            <CustomButton buttonText="Cancel" onClick={handleCancel} type="cancel" width="179px" height="49px" />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </Form>
                    </div>
                </div>

            </div>
        </>
    );
}

export default CreateVersionControl;
