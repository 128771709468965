import React, { useContext } from 'react'
import { AdminContext } from '../Context/admin.context'
import { Navigate,Outlet } from 'react-router-dom';
const ProtecedRoute = () => {
 const {isAuthenticated}= useContext(AdminContext);
 let authenticated=isAuthenticated();

 return authenticated?<Outlet/>:<Navigate to="/login"/>
}

export default ProtecedRoute