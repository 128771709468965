/*
Fucntion will return a string inidcating number of days between 2 days in string format
eg :30 Days
*/
export const getPeriod=(startDate,endDate)=>{
    const dateObj1=new Date(startDate);
    const dateObj2=new Date(endDate);
    const diff=dateObj2-dateObj1;
    const oneDayMs = 24 * 60 * 60 * 1000; 
    const days=Math.round(diff/oneDayMs);
    let numberOfDays=days+" "+"Days"
    return numberOfDays;
}