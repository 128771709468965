import React from 'react'
import './Settings.scss'
import { Form, Input } from 'antd'
import CustomButton from '../../../components/Admin/CustomButton'
import CustomInput from '../../../components/Admin/CustomInput'

const Settings = () => {
  return (
   <>
   <div className=' '>
    <div className='setting-page d-flex justify-content-center  align-items-center'>
      <div className='settings-card pt-5 ps-4 pe-4 pb-5'>
        <div>
          <div className='settings-heading'>
            Settings
          </div>
        </div>
        <div className='mt-5'>
          <div>
            <Form>
              <div className='d-flex'>
                <div>
                  <div className='settings-form-label'>GST % for Subscription Plan</div>
                  <Form.Item>
                    <Input style={{width:"225px",height:"55px",borderRadius:"6px",border:"none"}} />
                  </Form.Item>
                </div>
                <div className='ms-3'>
                <div className='settings-form-label'>GST % for Coin purchase</div>
                  <Form.Item>
                  <Input style={{width:"225px",height:"55px",borderRadius:"6px",border:"none"}} />
                  </Form.Item>
                </div>
                <div className='ms-3'>
                <div className='settings-form-label'>One Rupee equal to </div>
                  <Form.Item>
                  <Input style={{width:"225px",height:"55px",borderRadius:"6px",border:"none"}} />
                  </Form.Item>
                </div>
                <div className='ms-3'>
                <div className='settings-form-label'>One Rose equal to </div>
                  <Form.Item>
                  <Input style={{width:"225px",height:"55px",borderRadius:"6px",border:"none"}} />
                  </Form.Item>
                </div>
              
              </div>
              <div className='d-flex justify-content-center mt-4'>
                  <CustomButton type="save" buttonText="Submit" width="225px" height="49px"/>
                </div>
            </Form>
          </div>
        </div>
        <div></div>
      </div>
    </div>
   </div>
   </>
  )
}

export default Settings